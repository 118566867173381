import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { InputGeneralProps } from "../../../shared/types";

const { TextArea } = Input;

export default function InputTextArea(props: InputGeneralProps) {
  const [inputState, setInputState] = useState(undefined);
  const [className, setClassName] = useState<string>("form__input");

  const handleChange = (e: any) => {
    setInputState(e.target.value);
    props.onChange && props.onChange(e.target.value);
  };

  const onBlur = () => {
    if (props.required && props.required) {
      const classname = "form__input";
      if (
        inputState === "" ||
        inputState === null ||
        inputState === undefined
      ) {
        setClassName(classname + " form__label--error");
      } else {
        setClassName(classname);
      }
    }
  };

  useEffect(() => {
    setInputState(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    if (!props.required || !props.enabled) {
      setClassName("form__input");
    }
  }, [props.required, props.enabled]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TextArea
        rows={4}
        allowClear
        disabled={!props.enabled}
        className={className}
        value={inputState}
        onChange={handleChange}
        onBlur={!props.onBlur ? onBlur : undefined}
        placeholder={props.showPlaceholder ? props.placeholder : ""}
      />
    </div>
  );
}
