import { takeLatest, put, call } from "redux-saga/effects";

import { LOGIN_PROFILE_USER, LOGIN_USER, RESET_USER } from "./actionTypes";

import {
  loginUserFail,
  loginUserProfileFail,
  loginUserProfileSuccess,
  loginUserSuccess,
  resetUserFail,
  resetUserSuccess,
} from "./actions";
import { AuthenticateReponse } from "../../core/auth/Type";
import {
  loginWithUsernameAndPassword,
  getProfileUser,
} from "../../core/auth/AuthService";
import { API_URL } from "../../config/general-config";
import { apiPost } from "../../shared/ApiService";
import { Endpoints } from "../../config/endpoints";

function* onLoginUser(payload: any) {
  try {
    const { username, password } = payload;
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(loginWithUsernameAndPassword(username, password));
    });

    yield put(loginUserSuccess(response));
  } catch (error: any) {
    yield put(loginUserFail(error.response));
  }
}

function* onLoginProfileUser(payload: any) {
  try {
    const response: AuthenticateReponse = yield call(() => {
      return Promise.resolve(getProfileUser());
    });
    yield put(loginUserProfileSuccess(response));
  } catch (error: any) {
    yield put(loginUserProfileFail(error.response));
  }
}

function* onResetUser(payload: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiPost({
          url: `${API_URL}/${Endpoints.RESET}`,
          body: payload.username,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(resetUserSuccess(response));
  } catch (error: any) {
    yield put(resetUserFail(error.response));
  }
}

function* UserSaga() {
  yield takeLatest(LOGIN_USER, onLoginUser);
  yield takeLatest(LOGIN_PROFILE_USER, onLoginProfileUser);
  yield takeLatest(RESET_USER, onResetUser);
}

export default UserSaga;
