import * as types from "./actionTypes";

const initialState = {
  rubro: undefined,
  loadingRubro: false,
  error: {
    message: "",
  },
};

const RubroReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_RUBRO:
      state = { ...state, loadingRubro: true };
      break;

    case types.GET_RUBRO_SUCCESS:
      state = { ...state, rubro: actionPayload, loadingRubro: false };
      break;

    case types.GET_RUBRO_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loadingRubro: false,
      };
      break;
  }

  return state;
};

export default RubroReducer;
