import * as types from "./actionTypes";

const initialState = {
  quote: undefined,
  quoteToDetail: undefined,
  loading: false,
  quoteSearch: undefined,
  error: {
    message: "",
  },
};

const QuoteReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.SAVE_QUOTE:
    case types.GET_QUOTE_SEARCH:
    case types.ADD_QUOTE_TO_QUOTE:
    case types.CLEAN_QUOTE_TO_QUOTE:
      state = { ...state, loading: true };
      break;

    case types.SAVE_QUOTE_SUCCESS:
      state = { ...state, quote: actionPayload, loading: false };
      break;

    case types.GET_QUOTE_SEARCH_SUCCESS:
      state = { ...state, quoteSearch: actionPayload, loading: false };
      break;

    case types.ADD_QUOTE_TO_QUOTE_SUCCESS:
      state = { ...state, quoteToDetail: actionPayload, loading: false };
      break;

    case types.CLEAN_QUOTE_TO_QUOTE_SUCCESS:
      state = { ...initialState };
      break;

    case types.SAVE_QUOTE_FAIL:
    case types.GET_QUOTE_SEARCH_FAIL:
    case types.ADD_QUOTE_TO_QUOTE_FAIL:
    case types.CLEAN_QUOTE_TO_QUOTE_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default QuoteReducer;
