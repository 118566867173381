import React, { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { useDispatch, useSelector } from "react-redux";
import { Col, Pagination, Row, Table } from "antd";

import { ReduxType } from "../../shared/types";
import { ColumnsType } from "antd/lib/table";
import { InputNumber } from "../../components/inputs";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { getUserInfoAction } from "../../store/balance/actions";
import {
  addProductToQuoteAction,
  setComplementsAction,
} from "../../store/products/actions";
import { findIndex, loadComplements } from "../../shared/helper";
import _ from "lodash";
import { IProductComplements, IProductQuote, IProductQuoteAditionalComplements } from "../../core/models/products";
import { handleAditionalComplementChange } from "../../shared/helpers/complementsHelper";

const ComplementsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationHeight, setPaginationHeight] = useState(0);
  const paginationRef = useRef<any>(null);

  const { state }: any = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productData, balanceData } = useSelector((state: ReduxType) => {
    return state;
  });

  const getUserData = async () => {
    const url = `${API_URL}/${Endpoints.USER}`;
    await dispatch(getUserInfoAction(url));
  };

  useEffect(() => {
    if (paginationRef && paginationRef.current)
      setPaginationHeight(paginationRef.current.clientHeight);
  }, [paginationRef]);

  const columns: ColumnsType<any> = [
    {
      title: "Complemento",
      dataIndex: "description",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "30%",
      render: (value, record, index) => (
        <div className="center-complements-input">
          <InputNumber
            enabled
            name="cantidad"
            required
            onChange={(value) => onChange(value, record)}
            type="text"
            initialValue={value}
            key={index}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!balanceData.user) getUserData();

  }, []);

  useEffect(() => {
    if (!productData.productComplements) {
      doRequestForComplements();
    }
  }, [productData.productComplements]);

  const doRequestForComplements = async () => {
    const complements = await loadComplements(state.productElement);
    if (_.isEqual(complements, productData.productComplements)) return;
    await dispatch(setComplementsAction(complements));
  };

  const onChange = async ( newQuantity: number, record:IProductQuoteAditionalComplements ) => 
  {
    const { productQuote } = productData;
    const { productQuoteIndex }  = state;

    const updated:IProductQuote[] = handleAditionalComplementChange( newQuantity, productQuoteIndex, productQuote, record );

    await dispatch(addProductToQuoteAction([...updated]));
  };

  const dataSourceTable: any[] | undefined =
    productData.productComplements &&
    productData.productComplements
      .slice( currentPage * 8 - 8, currentPage * 8 - 8 + 8 )
      .map(( complemnt: IProductComplements ) => 
      {
        const { producto_complemento, _id } = complemnt;
        const { productQuote, productPriceList } = productData;
        const priceList         = Object.values(productPriceList.items);
        const matchedPrice: any = priceList.find((value: any) => {
          return value.producto_id == producto_complemento._id;
        });

        const matchetProduct = productQuote.find(( product, productIndex:number) => { 
          return productIndex == state.productQuoteIndex
        });

        const matchedComplement = matchetProduct?.complementosAdicionales
        .find((value: IProductQuoteAditionalComplements) => {
          return value.productId == matchedPrice.producto_id;
        });

        return {
          id         : _id,
          code        : producto_complemento.codigo,
          description : producto_complemento.descripcion,
          exists      : typeof matchedComplement == 'object',
          quantity    : matchedComplement ? matchedComplement.quantity : 0,
          price       : matchedPrice && matchedPrice.precio || 0,
          productId   : matchedPrice && matchedPrice.producto_id,
        };
  });

  return (
    <AuthenticatedRoute
      path={Paths.COMPLEMENTS}
      withHeader
      goBack={() =>
        navigate(Paths.PRODUCTDETAIL, {
          state: {
            formType: state.formType,
            editMode: state.editMode,
            canEdit: state.canEdit,
            ref: state && state.ref,
          },
        })
      }
      headerNavigationName={"Complementos"}
    >
      {!isLoading && (
        <>
          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: paginationHeight, marginTop: 15 }}
          >
            <Col span={24}>
              <Table
                id={"mytable"}
                columns={columns}
                dataSource={dataSourceTable}
                pagination={false}
              />
            </Col>
          </Row>
          {productData.productComplements &&
            productData.productComplements.length > 0 && (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f0f0f0",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
                ref={paginationRef}
              >
                <Pagination
                  style={{ float: "right" }}
                  current={currentPage}
                  pageSize={8}
                  onChange={(page) => setCurrentPage(page)}
                  total={productData.productComplements.length}
                  showSizeChanger={false}
                />
              </div>
            )}
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default ComplementsPage;
