import { Col, Form, Row, FormInstance, Button, message, Image } from "antd";
import React, { useEffect, useState } from "react";
import { Separator } from "../../separator";
import { useLocation } from "react-router";

import { useNavigate } from "react-router-dom";
import { Paths } from "../../../config/paths";
import { SearchOutlined } from "@ant-design/icons";
import { FormItemComponent } from "../formItemComponent";
import { InputSelect } from "../../inputs";
import { findIndex } from "../../../shared/helper";
import imageIcon from "../../../assets/icon/image.png";
import { useDispatch, useSelector } from "react-redux";
import {
  FormTypeEnum,
  QuoteAndOrderMainFormProps,
  ReduxType,
} from "../../../shared/types";
import {
  getProductPriceListAction,
  getProductsSelectAction,
  setProductAction,
} from "../../../store/products/actions";
import { getRubroAction } from "../../../store/rubro/actions";
import { ModalImage } from "../../modalImage";
import { isAdmin } from "../../../core/auth/AuthService";
import { setClientAction } from "../../../store/clients/actions";
import { API_URL } from "../../../config/general-config";
import { Endpoints } from "../../../config/endpoints";
import { getUserInfoAction } from "../../../store/balance/actions";

const QuoteAndOrderMainForm = (props: QuoteAndOrderMainFormProps) => {
  const [isModalImageVisible, setIsModalImageVisible] = useState<
    string | undefined
  >(undefined);
  const [verifyAdmin, setVerifyAdmin] = useState<boolean | undefined>(
    undefined
  );
  const [rubroSelected, setRubroSelected] = useState<string | undefined>();
  const [productSelected, setProductSelected] = useState<string | undefined>();
  const [isFirstSelectEnabled, setIsFirstSelectEnabled] = useState(false);
  const [isSecondSelectEnabled, setIsSecondSelectEnabled] = useState(false);

  const formRef = React.createRef<FormInstance>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showSearchButton, formType } = props;
  const { state }: any = useLocation();

  const { balanceData, clientData, productData, rubroData } = useSelector(
    (state: ReduxType) => {
      return state;
    }
  );

  useEffect(() => {
    if (verifyAdmin == undefined) {
      setVerifyAdmin(isAdmin());
    } else if (!verifyAdmin) {
      doRequestForUserLogged();
    }
  }, [verifyAdmin]);


  /* 
    la condicion !isAdmin() se coloca, porque en caso de ser un usuario administrador
    el cliente es seleccionado de un selector a diferencia de otros roles en el cual
    el cliente se setea automaticamente de sus datos de usuario.
  */
  useEffect(() => {
    if (balanceData.user && !isAdmin()) {
      setClientFromUserLogged();
    }
  }, [balanceData.user]);

  useEffect(() => {
    if (!productData.productPriceList) {
      doRequestForPrice();
    }
  }, []);

  useEffect(() => {
    if (clientData.client) {
      doRubroRequest(clientData.client._id);
    }
  }, [clientData]);

  useEffect(() => {
    if (clientData.client && rubroData.rubro) {
      setIsFirstSelectEnabled(true);
    } else {
      setIsFirstSelectEnabled(false);
    }
  }, [clientData, rubroData]);

  useEffect(() => {
    if (
      rubroData.rubro &&
      productData.productsSelect &&
      productData.productsSelect.length > 0
    ) {
      setIsSecondSelectEnabled(true);
    } else {
      setIsSecondSelectEnabled(false);
    }
  }, [rubroData, productData]);

  useEffect(() => {
    if (rubroSelected) {
      doProductRequest(Number(rubroSelected));
    } else {
      setIsSecondSelectEnabled(false);
    }
  }, [rubroSelected]);

  const setClientFromUserLogged = async () => {
    await dispatch(setClientAction(balanceData.user));
  };

  const doRequestForUserLogged = async () => {
    const url = `${API_URL}/${Endpoints.USER}`;
    await dispatch(getUserInfoAction(url));
  };

  const doRequestForPrice = async () => {
    await dispatch(getProductPriceListAction());
  };

  const doRubroRequest = async (clientId: number) => {
    await dispatch(getRubroAction(clientId));
  };

  const doProductRequest = async (rubroId: number) => {
    await dispatch(getProductsSelectAction(rubroId));
  };

  const handleSubmit = async (values: any) => {
    const index = findIndex(productData.productsSelect, "_id", values.producto);
    if (index !== -1) {
      const p = productData.productsSelect[index];
      await dispatch(setProductAction(p));
      navigate(Paths.NEWPRODUCT, {
        state: {
          from:
            formType == FormTypeEnum.Quote
              ? Paths.NEWQUOTATION
              : Paths.NEWORDER,
          formType: formType,
          editMode: state && state.editMode,
          canEdit: state && state.canEdit,
        },
      });
    }
  };

  const openFileImage = () => {
    if (productSelected) {
      const index = findIndex(
        productData.productsSelect,
        "_id",
        productSelected
      );
      if (index !== -1) {
        if (!productData.productsSelect[index].photo) {
          message.error("El producto no tiene una foto cargada.");
        } else {
          setIsModalImageVisible(productData.productsSelect[index].photo);
        }
      }
    } else {
      message.error("Debe seleccionar un producto para poder ver su imagen.");
    }
  };

  return (
    <>
      <Form
        name="basic"
        initialValues={undefined}
        onFinish={handleSubmit}
        layout="vertical"
        ref={formRef}
      >
        {verifyAdmin !== undefined && verifyAdmin && (
          <Separator marginTop={20}>
            <Button
              onClick={() =>
                navigate(Paths.CLIENTS, {
                  state: {
                    from:
                      formType == FormTypeEnum.Quote
                        ? Paths.NEWQUOTATION
                        : Paths.NEWORDER,
                    formType: formType,
                    editMode: state && state.editMode,
                    canEdit: state && state.canEdit,
                  },
                })
              }
              className={"custom-button custom-button-blue"}
            >
              <Row>
                <div className={"entity-search"}>
                  <div className={"entity-search-left"}>
                    {clientData && clientData.client
                      ? `${clientData.client.razonsocial} - Cambiar Cliente`
                      : `Elegir Cliente`}
                  </div>
                  <div className={"entity-search-right"}>
                    <SearchOutlined />
                  </div>
                </div>
              </Row>
            </Button>
          </Separator>
        )}
        <Separator marginTop={20}>
          <FormItemComponent inputName={"rubro"} rules={[{ name: "required" }]}>
            <InputSelect
              enabled={isFirstSelectEnabled}
              required
              showPlaceholder
              name={"rubro"}
              placeholder={"Seleccioná un rubro"}
              optionList={rubroData.rubro}
              onChange={(value) => setRubroSelected(value)}
            />
          </FormItemComponent>

          <FormItemComponent
            inputName={"producto"}
            rules={[{ name: "required" }]}
          >
            <InputSelect
              enabled={isSecondSelectEnabled}
              required
              showPlaceholder
              name={"producto"}
              placeholder={"Seleccioná un producto"}
              optionList={productData.productsSelect}
              onChange={(value) => setProductSelected(value)}
            />
          </FormItemComponent>
        </Separator>

        {productSelected && (
          <Separator marginTop={20}>
            <Col xs={12} xl={10}>
              <div>
                <Button
                  onClick={() => openFileImage()}
                  className={"custom-button"}
                  style={{ border: "1px solid #438EB9" }}
                >
                  <Row>
                    <div className={"entity-search"}>
                      <Image
                        style={{
                          objectFit: "contain",
                          width: 24,
                          height: 24,
                        }}
                        src={imageIcon}
                        preview={false}
                      />
                      <span
                        className={`custom-span-text-file custom-span-text`}
                        style={{
                          marginLeft: 35,
                          color: "#438EB9",
                          marginTop: 0,
                        }}
                      >
                        Ver Foto
                      </span>
                    </div>
                  </Row>
                </Button>
              </div>
            </Col>
          </Separator>
        )}

        <Separator marginTop={20}>
          <Button
            type="primary"
            htmlType="submit"
            className={"custom-button"}
            style={{
              backgroundColor: "#FFFFFF",
              color: rubroSelected && productSelected ? "#438EB9" : "#828282",
              border:
                rubroSelected && productSelected
                  ? "1px solid #438EB9"
                  : "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            Agregar Producto
          </Button>
        </Separator>

        {showSearchButton && (
          <Separator marginTop={20}>
            <Button
              type="primary"
              onClick={() => {
                if (!isFirstSelectEnabled) {
                  return message.error(
                    "Debe seleccionar un Cliente antes de avanzar en la busqueda de un producto."
                  );
                }
                navigate(Paths.PRODUCT, {
                  state: {
                    from:
                      formType == FormTypeEnum.Quote
                        ? Paths.NEWQUOTATION
                        : Paths.NEWORDER,
                    formType: formType,
                    editMode: state && state.editMode,
                    canEdit: state && state.canEdit,
                    ref: state && state.ref,
                  },
                });
              }}
              className={"custom-button"}
              style={{
                backgroundColor: "#FFFFFF",
                color:
                  clientData.client && clientData.client._id
                    ? "#438EB9"
                    : "#828282",
                border:
                  clientData.client && clientData.client._id
                    ? "1px solid #438EB9"
                    : "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              Buscador de producto
            </Button>
          </Separator>
        )}
      </Form>
      {isModalImageVisible && (
        <ModalImage
          imageUrl={isModalImageVisible}
          isVisible={!!isModalImageVisible}
          clossable={true}
          onHide={() => setIsModalImageVisible(undefined)}
        />
      )}
    </>
  );
};

export default QuoteAndOrderMainForm;
