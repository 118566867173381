import * as types from "./actionTypes";

export const getUsefulFileAction = (usefulFileUrl: string, folderId:string|undefined) => {
  return {
    type: types.GET_USEFUL_FILES,
    usefulFileUrl,
    folderId
  };
};

export const getUsefulFileFail = (error: any) => {
  return {
    type: types.GET_USEFUL_FILES_FAIL,
    payload: error,
  };
};

export const getUsefulFileSuccess = (usefulFiles: any,folderId:string) => {
  return {
    type: types.GET_USEFUL_FILES_SUCCESS,
    payload: {usefulFiles,folderId}
  };
};

export const getFoldersAction = () => {
  return {
    type: types.GET_FOLDERS
  };
};

export const getFoldersSuccess = (folders: any) => {
  return {
    type: types.GET_FOLDERS_SUCCESS,
    payload: folders,
  };
};

export const getFoldersFail = (error: any) => {
  return {
    type: types.GET_FOLDERS_FAIL,
    payload: error,
  };
};

export const cleanUsefulFilesStateAction = () => {
  return {
    type: types.CLEAN_USEFUL_FILES_STATE
  };
};