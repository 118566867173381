import React, { useEffect, useRef, useState } from "react";
import { HeaderComponent } from "../../components/base";

import { useNavigate, useParams } from "react-router-dom";

import Save from "../../assets/icon/save_alt.png";
import Editar from "../../assets/icon/create_24px.png";
import Ver from "../../assets/icon/visibility.png";
import Anular from "../../assets/icon/anular.png";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { useDispatch, useSelector } from "react-redux";
import { Col, Pagination, Row, Table, FormInstance, message } from "antd";
import { SimpleSearchForm } from "../../components/forms";
import { FormTypeEnum, OrdersDataProps, ReduxType } from "../../shared/types";
import { ColumnsType } from "antd/lib/table";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import {
  addOrderToQuoteAction,
  getOrdersSearchAction,
  setOrderToCancelAction,
} from "../../store/orders/actions";
import { DropdownMenu } from "../../components/dropdownMenu";
import { InputText } from "../../components/inputs";
import Form from "antd/lib/form/Form";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { apiPut, getHeaders } from "../../shared/ApiService";
import { ModalForm } from "../../components/modalForm";
import { getClientByIdAction } from "../../store/clients/actions";
import {
  getOrderStateId,
  getStateImg,
  verifyNullState,
} from "../../shared/helper";
import { getUserInfoAction } from "../../store/balance/actions";
import { fromPairs } from "lodash";
import { hasPermission, isAdmin } from "../../core/auth/AuthService";
import NumberFormat from 'react-number-format';
import moment from "moment";

const OrderPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<any>(undefined);
  const [currentSearchValue, setCurrentSearchValue] = useState<
    string | undefined
  >();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationHeight, setPaginationHeight] = useState(0);
  const paginationRef = useRef<any>(null);

  const [verifyAdmin, setVerifyAdmin] = useState<boolean | undefined>(
    undefined
  );
  const formRefModal = React.createRef<FormInstance>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { balanceData, ordersData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (verifyAdmin == undefined) {
      setVerifyAdmin(isAdmin());
    }
  }, [verifyAdmin]);

  useEffect(() => {
    if (paginationRef && paginationRef.current)
      setPaginationHeight(paginationRef.current.clientHeight);
  }, [paginationRef]);

  useEffect(() => {
    if (balanceData.user && !ordersData.productsSearch) {
      doRequest(1, currentSearchValue);
    }
  }, [balanceData.user]);

  useEffect(() => {
    if (!balanceData.user) {
      getUserData();
    }
  }, []);

  useEffect(() => {
    if (balanceData.user && currentPage) {
      doRequest(currentPage, currentSearchValue);
    }
  }, [currentPage]);

  useEffect(() => {
    if (balanceData.user && currentSearchValue) {
      doRequest(1, currentSearchValue);
    }
  }, [currentSearchValue]);

  const getUserData = async () => {
    const url = `${API_URL}/${Endpoints.USER}`;
    await dispatch(getUserInfoAction(url));
  };

  const doRequest = async (page: number, searchValue: string | undefined) => {
    let url =
      params && params.orderType == FormTypeEnum.Order
        ? `${API_URL}/${Endpoints.ORDERS_SEARCH}&&`
        : `${API_URL}/${Endpoints.ORDERS_DRAFT_SEARCH}&&`;

    if (!isAdmin()) {
      url += `clienteId=${balanceData.user._id}&&`;
    }

    if (searchValue) {
      url += `referencia=${searchValue}`;
    }
    if (page) {
      url += `${searchValue ? "&" : ""}page=${page}&pageSize=12`;
    }

    await dispatch(getOrdersSearchAction(url));
  };

  const handleViewAndEditionAction = async (
    order: any,
    editMode: boolean,
    canEdit: boolean
  ) => {
    const url = `${API_URL}/${Endpoints.ORDERS_SEARCH_FROM_LIST}/${order._id}`;
    await dispatch(getClientByIdAction(order.cliente._id));
    await dispatch(addOrderToQuoteAction(url));
    navigate(Paths.PRODUCTDETAILLIST, {
      state: {
        from: `${Paths.ORDER}/${params.orderType}`,
        formType: FormTypeEnum.Order,
        orderType: params.orderType,
        editMode: editMode,
        canEdit: canEdit,
      },
    });
  };

  const handleDownloadAction = async (value: any) => {
    setIsModalVisible({ referencia: value.referencia, _id: value._id });
    await addOrderToQuoteFunction(value._id);
  };

  const columns: any = [
    {
      title: "Referencia",
      dataIndex: "referencia",
      show: true
    },
    {
      title: "Costo Final",
      dataIndex: "costoFinal",
      render: (value:any) => <span>
                          <NumberFormat 
                            value={value} 
                            displayType={'text'} 
                            thousandSeparator={true}
                            decimalScale={2} 
                            fixedDecimalScale={true}
                            prefix={'$'} />
                          </span>,
      show: hasPermission('READ_FINAL_COST')
    },
    {
      title: "Fecha de entrega",
      dataIndex: "fecha",
      show: true
    },
    {
      title: "Estado",
      dataIndex: "estado",
      show: true
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      show: true,
      render: (value:any, record:any, index:any) => {
        const nullState = verifyNullState(
          record.estadoRaw,
          params && params.orderType != FormTypeEnum.Order
        );
        const menu = [
          {
            name: "Ver",
            icon: <img src={Ver} alt="view-icon" />,
            action: () =>
              handleViewAndEditionAction(record.order, false, nullState),
          },
          {
            name: "Editar",
            icon: <img src={Editar} alt="edit-icon" />,
            action: () =>
              handleViewAndEditionAction(record.order, true, nullState),
            disabled: !nullState,
          },
        ];

        if (
          verifyAdmin != undefined &&
          verifyAdmin &&
          params &&
          params.orderType == FormTypeEnum.Order
        ) {
          menu.push({
            name: "Descargar",
            icon: <img src={Save} alt="save-icon" />,
            action: () => handleDownloadAction(record),
          });
        }
        if (verifyAdmin != undefined && verifyAdmin && nullState) {
          menu.push({
            name: "Anular",
            icon: <img src={Anular} alt="cancel-icon" />,
            action: () => handleCancelAction(record, value),
          } as any);
        }
        return (
          <div style={{ textAlign: "center", fontSize: 15 }}>
            <DropdownMenu menu={menu} />
          </div>
        );
      },
    },
  ].filter((row)=> {
    return row.show;
  });

  const handleCancelAction = async (order: any, value: any) => {
    await dispatch(setOrderToCancelAction(order.order));
    navigate(`${Paths.CANCELORDER}/${params.orderType}`, {
      state: {
        from: `${Paths.ORDER}/${params.orderType}`,
        formType: FormTypeEnum.Order,
        orderId: value,
      },
    });
  };

  const dataSourceTable: any[] | undefined =
    ordersData.ordersSearch &&
    ordersData.ordersSearch.data &&
    ordersData.ordersSearch.data.map((col: OrdersDataProps, index: number) => {
      return {
        key: index,
        _id: col._id,
        referencia: col.referencia,
        costoFinal: (col.total - (col.total * col.bonificacion / 100)),
        estado: getStateImg(col, FormTypeEnum.Order),
        estadoRaw: getOrderStateId(col),
        order: col,
        fecha: col.fecha_entrega && moment(col.fecha_entrega).format("DD/MM/YYYY"),
        acciones: col._id,
      };
    });

  const addOrderToQuoteFunction = async (id: any) => {
    const url = `${API_URL}/${Endpoints.ORDERS_SEARCH}/${id}`;
    await dispatch(addOrderToQuoteAction(url));
  };

  const downloadPDF = async (
    type: "EXPEDICION" | "FACTURACION",
    fileName: string
  ) => {
    const url =
      type === "EXPEDICION"
        ? `${API_URL}/${Endpoints.GET_PDF_EXPEDICION}${isModalVisible._id}`
        : `${API_URL}/${Endpoints.GET_PDF_FACTURACION}${isModalVisible._id}`;
    try {
      fetch(url, {
        headers: getHeaders(),
        method: "GET",
      }).then(async (value) => {
        return await value.blob().then((b: any) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(b);
          link.setAttribute("download", fileName);
          link.click();
        });
      });
    } catch (error: any) {
      message.error(error.message, 8);
      return;
    }
  };

  const getAndDownloadPDFs = async () => {
    setIsSubmitting(true);
    await Promise.all([
      downloadPDF("EXPEDICION", `Expedicion-${isModalVisible._id}.pdf`),
      downloadPDF("FACTURACION", `Facturacion-${isModalVisible._id}.pdf`),
    ]);
    setIsModalVisible(undefined);
    setIsSubmitting(false);
  };

  const updateOrder = async (order: any) => {
    const url = `${API_URL}/${Endpoints.UPDATE_ORDER}${isModalVisible._id}`;
    await apiPut({
      url,
      body: order,
      unauthorizedCallback: () => {},
    }).finally(async () => {
      await doRequest(currentPage, currentSearchValue);
    });
  };

  const onSubmit = async (values: any) => {
    const orderClone = {
      ...ordersData.orderQuote,
      ...values,
    };
    await updateOrder(orderClone);
    await getAndDownloadPDFs();
  };

  return (
    <AuthenticatedRoute
      path={Paths.ORDER}
      withHeader
      goBack={() => navigate(Paths.ROOT)}
      headerNavigationName={
        params && params.orderType == FormTypeEnum.Order
          ? "Mis Pedidos"
          : "Mis Borradores"
      }
    >
      {!isLoading && (
        <>
          <SimpleSearchForm
            inputName={"referenciaSearch"}
            placeholder={"Referencia"}
            onSearch={(value) => setCurrentSearchValue(value)}
          />

          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: paginationHeight }}
          >
            <Col span={24}>
              <Table
                id={"mytable"}
                loading={ordersData && ordersData.loading}
                columns={columns}
                dataSource={dataSourceTable}
                pagination={false}
                className={"align-center-table-header"}
                rowClassName={"max-width-cell align-center-table-cell"}
              />
            </Col>
          </Row>
          {ordersData.ordersSearch &&
            ordersData.ordersSearch.data &&
            ordersData.ordersSearch.data.length > 0 && (
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#f0f0f0",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
                ref={paginationRef}
              >
                <Pagination
                  pageSize={12}
                  style={{ float: "right" }}
                  current={currentPage}
                  onChange={(page) => setCurrentPage(page)}
                  total={ordersData.ordersSearch.total}
                  showSizeChanger={false}
                />
              </div>
            )}
        </>
      )}
      <ModalForm
        handleCancel={() => setIsModalVisible(undefined)}
        title="Descargar Pedido"
        okText="Descargar"
        cancelText="Cancelar"
        isModalVisible={!!isModalVisible}
        isSubmitting={isSubmitting}
      >
        {!!isModalVisible && (
          <Form
            name="modal-form"
            initialValues={undefined}
            layout="vertical"
            onFinish={onSubmit}
            ref={formRefModal}
          >
            <FormItemComponent
              inputName="referencia"
              rules={[{ name: "required" }]}
            >
              <InputText
                enabled
                initialValue={isModalVisible.referencia}
                name="referencia"
                floatLabel="Referencia"
                required
                type="text"
              />
            </FormItemComponent>
          </Form>
        )}
      </ModalForm>
    </AuthenticatedRoute>
  );
};

export default OrderPage;
