import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { FilterProps } from "../../../shared/types";

export default function (props: FilterProps) {
  const [inputState, setInputState] = useState<any>(undefined);

  const onChange = (e: any) => {
    props.onChange &&
      props.onChange(
        e.target.checked !== undefined && e.target.checked !== null
          ? e.target.checked === true
          : null
      );
    setInputState(
      e.target.checked !== undefined && e.target.checked !== null
        ? e.target.checked === true
        : null
    );
  };

  useEffect(() => {
    setInputState(props.initialValue);
  }, [props.initialValue]);

  return (
    <div
      style={{
        marginLeft: 6,
      }}
    >
      <Checkbox
        onChange={onChange}
        checked={
          inputState !== null && inputState !== undefined
            ? inputState
              ? true
              : false
            : false
        }
        value={
          inputState !== null && inputState !== undefined
            ? inputState
              ? true
              : false
            : false
        }
        disabled={!props.enabled}
      />{" "}
      {props.checkboxLabel && (
        <span className={"inputCheckLabel"}>{props.checkboxLabel}</span>
      )}
    </div>
  );
}
