import React, { useEffect, useState } from "react";

import { Image } from "antd";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DropdownMenu } from "../../dropdownMenu";

import deleteIcon from "../../../assets/icon/productDetail/delete.png";
import editIcon from "../../../assets/icon/productDetail/edit.png";
import copyIcon from "../../../assets/icon/productDetail/copy.png";
import addIcon from "../../../assets/icon/productDetail/add.png";
import { FormTypeEnum, ReduxType } from "../../../shared/types";
import {
  findIndex,
  getRandomNumber,
  loadComplements,
} from "../../../shared/helper";
import {
  addProductToQuoteAction,
  addProductToQuoteToDeleteAction,
  deleteProductOrderAction,
  setProductAction,
} from "../../../store/products/actions";
import { Paths } from "../../../config/paths";
import * as _ from "lodash";
import { IProductQuote  } from '../../../core/models/products';

const ProductDetailMenu = (props: any) => {
  const { onlyView, productQuoteElement, productQuoteIndex } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showComplement, setShowComplement] = useState<boolean>(false);
  const { state }: any = useLocation();

  const { 
    productData, 
    ordersData:{orderQuote}, 
    quoteData:{quoteToDetail} 
  } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!productData.loading) {
      doRequestForComplements();
    }
  }, [productData.loading]);

  const doRequestForComplements = async () => {
    const complements = await loadComplements(productQuoteElement);
    setShowComplement(complements && complements.length > 0);
  };

  const complementProduct = async (productElement: any) => {
    navigate(Paths.COMPLEMENTS, {
      state: {
        from:
          state && state.formType == FormTypeEnum.Quote
            ? Paths.NEWQUOTATION
            : Paths.NEWORDER,
        formType: state && state.formType,
        canEdit: state && state.canEdit,
        editMode: state && state.editMode,
        ref: state && state.ref,
        productElement,
        productQuoteIndex
      },
    });
  };

  const copyProduct = async ( product: any ) => {
  /* cuando se copia un producto, hay que dejar por fuera el _id ( en caso de estar copiando en pedido existente )
    para evitar errores de duplicados al actualizar el pedido. */
    const { _id, ...productCopy } = product;

    const updated = [
      ...productData.productQuote,       
      {
        ...productCopy,
        item: productData.productQuote.length + 1
      }
    ];

    await dispatch(addProductToQuoteAction(updated));
  };

  const editProduct = async (productQuoteElement: any) => {

    const updated = {
      ...productQuoteElement,
      editProduct       : true,
      productQuoteIndex : productQuoteIndex
    };

    await dispatch(setProductAction(updated));
    navigate(Paths.NEWPRODUCT, {
      state: {
        from: Paths.PRODUCTDETAIL,
        formType: state && state.formType,
        canEdit: state && state.canEdit,
        editMode: state && state.editMode,
        ref: state && state.ref,
      },
    });
  };

  const deleteProduct = async ( productQuoteElement:IProductQuote ) => {
   
    let itemCount = 0;
    
    const productQuoteUpdated:IProductQuote[] = productData.productQuote.filter(( product:IProductQuote, index:number ) => {
      return productQuoteIndex !== index;
    }).map(( product:IProductQuote ) => {
      itemCount++;
      return {
        ...product,
        item:itemCount
      };
    })

    /* orderQuote: contiene datos de un PEDIDO existente o undefined si estas en 'nueva cotizacion/pedido' */ 
    if(orderQuote)
    {
      /*
        Adicional: Eliminar items de un pedido existente requiere una peticion POST al backend para remover dicho item
        en la base de datos especificamente enviando 'deleteProductOrderAction' el ID del pedido y el ID del item.
        
      */
      await dispatch(deleteProductOrderAction(productQuoteElement._id,orderQuote._id));
    }

    /* quoteToDetail: contiene datos de una COTIZACION existente o undefined si estas en 'nueva cotizacion/pedido' */ 
    if(quoteToDetail)
    {
      /* 
      Adicional: Eliminar items de una cotización existente, requiere agregar el item en un array especial de "elementos borrados"
      que seran enviados con el resto de los datos de la cotización al backend para ser actualizados.
      */
      const productQuoteToDeleteUpdated:IProductQuote[] = [
        ...productData.productQuoteToDelete,
        productQuoteElement
      ];   
      await dispatch(addProductToQuoteToDeleteAction(productQuoteToDeleteUpdated));
    }

    await dispatch(addProductToQuoteAction(productQuoteUpdated));
  };

  return (
    <DropdownMenu
      key={getRandomNumber(5)}
      menu={[
        ( state &&
          showComplement &&
          !productQuoteElement._id ) && 
        {
            name: "Complementos",
            disabled: onlyView,
            action: () => !onlyView && complementProduct(productQuoteElement),
            icon: (
              <Image
                className={"menu-custom-icon"}
                src={addIcon}
                preview={false}
              />
            ),
        },
        {
          name: "Eliminar",
          disabled: onlyView,
          action: () => !onlyView && deleteProduct(productQuoteElement),
          icon: (
            <Image
              className={"menu-custom-icon"}
              src={deleteIcon}
              preview={false}
            />
          ),
        },
        {
          name: "Copiar",
          disabled: onlyView,
          action: () => !onlyView && copyProduct(productQuoteElement),
          icon: (
            <Image
              className={"menu-custom-icon"}
              src={copyIcon}
              preview={false}
            />
          ),
        },
        (state &&          
          (state.formType == FormTypeEnum.Quote && !state.editMode) || 
          (state.formType == FormTypeEnum.Order)) && {
          name: "Editar",
          disabled: onlyView,
          action: () => !onlyView && editProduct(productQuoteElement),
          icon: (
            <Image
              className={"menu-custom-icon"}
              src={editIcon}
              preview={true}
            />
          ),
        },
      ]}
    />
  );
};

export default ProductDetailMenu;
