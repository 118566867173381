import React, { useState } from "react";



export default function FloatLabel(props: {label: string, value: any, children: any}) {
  const [focus, setFocus] = useState(false);
  const { children, label, value } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? 'label-float' : '';

  return (
    <div
      className={'float-label ' + labelClass}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className="label">{label}</label>
    </div>
  );
}
