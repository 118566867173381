import { takeLatest, put, call } from "redux-saga/effects";

import { GET_FOLDERS, GET_USEFUL_FILES } from "./actionTypes";
import { getFoldersFail, getFoldersSuccess, getUsefulFileFail, getUsefulFileSuccess } from "./action";
import { apiGet } from "../../shared/ApiService";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

function* onGetUsefulFiles(payload: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.usefulFileUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getUsefulFileSuccess(response, payload.folderId));
  } catch (error: any) {
    yield put(getUsefulFileFail(error.response));
  }
}

function* onGetFolders() {

  const url = `${API_URL}/${Endpoints.FOLDERS}`;

  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: url,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getFoldersSuccess(response));
  } catch (error: any) {
    yield put(getFoldersFail(error.response));
  }
}

function* UsefulFileSaga() {
  yield takeLatest(GET_USEFUL_FILES, onGetUsefulFiles);
  yield takeLatest(GET_FOLDERS, onGetFolders);
}

export default UsefulFileSaga;
