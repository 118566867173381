import React from "react";

import { Dropdown, Menu, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const DropdownMenu = (props: any) => {
  const menu = (
    <Menu>
      {props.menu
        .filter((m: any) => {
          return m;
        })
        .map((m: any, index: number) => {
          return (
            <Menu.Item
              key={`Menu-${index}`}
              icon={m.icon && m.icon}
              disabled={m.disabled}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => m.action()}
              >
                {m.name}
              </a>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        arrow
        trigger={["click"]}
      >
        <Tooltip title={`Acciones`}>
          <MoreOutlined style={{ fontSize: 20 }} />
        </Tooltip>
      </Dropdown>
    </>
  );
};

export default DropdownMenu;
