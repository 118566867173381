import React from "react";
import { InputOptionDisplayProps } from "../../../shared/types";

export default function (props: InputOptionDisplayProps) {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {props.value}
      </div>
    </div>
  );
}
