import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Paths } from "../../config/paths";
import DashboardPage from "../../pages/base/DashboardPage";
import LoginPage from "../../pages/base/LoginPage";
import NotFoundPage from "../../pages/base/NotFoundPage";
import ClientPage from "../../pages/client/ClientPage";
import ConfigurationPage from "../../pages/configuration/ConfigurationPage";
import CreateOrderPage from "../../pages/order/CreateOrderPage";
import OrderPage from "../../pages/order/OrderPage";
import CreateProductPage from "../../pages/product/CreateProductPage";
import ProductDetailPage from "../../pages/product/ProductDetailPage";
import ProductPage from "../../pages/product/ProductPage";
import ProductReferencePage from "../../pages/product/ProductReferencePage";
import CreateQuotationPage from "../../pages/quotation/CreateQuotationPage";
import QuotationPage from "../../pages/quotation/QuotationPage";
import BalancePage from "../../pages/balance/BalancePage";
import ComplementsPage from "../../pages/order/ComplementsPage";
import ProductDetailListPage from "../../pages/product/ProductDetailListPage";
import OrderCancelPage from "../../pages/order/OrderCancelPage";
import UsefulFilesPage from "../../pages/usefulFiles/UsefulFilesPage";
import DaterPage from "../../pages/dater/DaterPager";
import LogoutPage from "../../pages/base/LogoutPage";
import ResetPage from "../../pages/reset/ResetPage";

export const RouterConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={Paths.ROOT} element={<DashboardPage />} />
        <Route path={Paths.LOGIN} element={<LoginPage />} />
        <Route path={Paths.LOGOUT} element={<LogoutPage />} />
        <Route path={Paths.PRODUCT} element={<ProductPage />} />
        <Route path={Paths.NEWPRODUCT} element={<CreateProductPage />} />
        <Route path={Paths.PRODUCTDETAIL} element={<ProductDetailPage />} />
        <Route path={Paths.COMPLEMENTS} element={<ComplementsPage />} />
        <Route
          path={Paths.PRODUCTDETAILLIST}
          element={<ProductDetailListPage />}
        />

        <Route
          path={Paths.PRODUCTREFERENCE}
          element={<ProductReferencePage />}
        />

        <Route path={`${Paths.ORDER}/:orderType`} element={<OrderPage />} />
        <Route
          path={`${Paths.CANCELORDER}/:orderType`}
          element={<OrderCancelPage />}
        />

        <Route path={Paths.CLIENTS} element={<ClientPage />} />
        <Route path={Paths.QUOTATION} element={<QuotationPage />} />
        <Route path={Paths.NEWORDER} element={<CreateOrderPage />} />
        <Route path={Paths.NEWQUOTATION} element={<CreateQuotationPage />} />
        <Route path={Paths.BALANCE} element={<BalancePage />} />
        <Route path={Paths.PAGENOTFOUNT} element={<NotFoundPage />} />
        <Route path={Paths.CONFIGURATION} element={<ConfigurationPage />} />
        <Route path={Paths.USEFULFILES} element={<UsefulFilesPage />} />
        <Route path={`${Paths.USEFULFILES}/folder/:folderId`} element={<UsefulFilesPage />} />
        <Route path={Paths.DATER} element={<DaterPage />} />
        <Route path={Paths.RESET} element={<ResetPage />} />
      </Routes>
    </BrowserRouter>
  );
};
