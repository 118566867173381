import { takeLatest, put, call } from "redux-saga/effects";

import {
  CLEAN_CLIENT_STATE,
  GET_CLIENTS,
  GET_CLIENT_ID,
  SET_CLIENT,
} from "./actionTypes";

import {
  getClientsFail,
  setClientFail,
  getClientsSuccess,
  setClientSuccess,
  cleanClientStateSuccess,
  cleanClientStateFail,
  getClientByIdSuccess,
  getClientByIdFail,
} from "./actions";

import { apiGet } from "../../shared/ApiService";
import { ClientsProps } from "../../shared/types";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";

function* onGetClients(payload: any) {
  try {
    const response: ClientsProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.clientsUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getClientsSuccess(response));
  } catch (error: any) {
    yield put(getClientsFail(error.response));
  }
}

function* onGetClientByIdAction(payload: any) {
  try {
    const response: ClientsProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.CLIENTS_WITH_PARAMETERS}/${payload.clientId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getClientByIdSuccess(response));
  } catch (error: any) {
    yield put(getClientByIdFail(error.response));
  }
}

function* onSetClient(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(setClientSuccess(payload.client));
  } catch (error: any) {
    yield put(setClientFail(error.response));
  }
}

function* onClientCleanState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanClientStateSuccess());
  } catch (error: any) {
    yield put(cleanClientStateFail(error.response));
  }
}

function* ClientSaga() {
  yield takeLatest(GET_CLIENTS, onGetClients);
  yield takeLatest(GET_CLIENT_ID, onGetClientByIdAction);
  yield takeLatest(CLEAN_CLIENT_STATE, onClientCleanState);
  yield takeLatest(SET_CLIENT, onSetClient);
}

export default ClientSaga;
