import React, { useEffect, useState } from "react";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { CardProps } from "../../shared/types";
import { CardComponent } from "../../components/card";

import { Paths } from "../../config/paths";
import { DashboardMenu } from "../../components/menu/const/DashboardMenu";
import { cleanClientStateAction } from "../../store/clients/actions";
import {
  addProductToQuoteAction,
  cleanProductStateAction,
} from "../../store/products/actions";
import { useDispatch } from "react-redux";
import { cleanQuoteToQuoteAction } from "../../store/quotes/actions";
import { cleanOrderStateAction } from "../../store/orders/actions";
import { cleanUsefulFilesStateAction } from "../../store/usefulFiles/action";

const DashboardPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    cleanAllData();
  }, []);

  const cleanAllData = async () => {
    await dispatch(cleanClientStateAction());
    await dispatch(cleanProductStateAction());
    await dispatch(cleanQuoteToQuoteAction());
    await dispatch(cleanOrderStateAction());
    await dispatch(addProductToQuoteAction([]));
    await dispatch(cleanUsefulFilesStateAction());
  };

  return (
    <AuthenticatedRoute
      dashboard
      withHeader
      withDashboardLogo
      path={Paths.ROOT}
    >
      {!isLoading && (
        <>
          {DashboardMenu &&
            DashboardMenu.map((dashMenu: CardProps, index: number) => (
              <CardComponent
                marginTop={index === 0 ? true : undefined}
                key={`card-${index}`}
                withIcon={dashMenu.withIcon}
                path={dashMenu.path}
                name={dashMenu.name}
                icon={dashMenu.icon}
                marginIcon={dashMenu.marginIcon}
              />
            ))}
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default DashboardPage;
