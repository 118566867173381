import { Card, Col, Image, Row } from "antd";
import React from "react";
import { CardProps } from "../../shared/types";
import { useNavigate } from "react-router-dom";

const CardComponent = (props: CardProps) => {
  const navigate = useNavigate();

  return (
    <div
      style={
        props.marginTop
          ? {
              margin: "5.5px 22px",
              marginLeft: 22,
              marginRight: 22,
              marginBottom: 5.5,
              marginTop: 17,
            }
          : { margin: "5.5px 22px" }
      }
    >
      <Card
        bordered={false}
        className="card-hover-effect"
        onClick={() => props.path && navigate(props.path)}
      >
        <Card.Grid
          style={{
            width: "100%",
            borderLeft: "8px solid #2D9CDB",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            background: "#F2F2F2",
            borderRadius: 4,
            padding: 15,
          }}
        >
          <Row gutter={0} align="middle">
            <Col
              span={20}
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: 24,
                lineHeight: "16px",
                letterSpacing: 0.4,
                color: "#706F6E",
              }}
            >
              {props.name}
            </Col>
            <Col span={3}>
              <Row gutter={[0, 5]} align="top" justify="end">
                {props.withIcon && (
                  <Col
                    style={{
                      marginRight: props.marginIcon
                        ? props.marginIcon
                        : undefined,
                      display: "flex",
                    }}
                  >
                    <Image
                      style={{ objectFit: "contain", width: 42, height: 38 }}
                      src={props.icon}
                      preview={false}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Grid>
      </Card>
    </div>
  );
};

export default CardComponent;
