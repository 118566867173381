import React, { useState } from "react";
import { Col, Form, FormInstance, Image, message } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { Paths } from "../../config/paths";

import { InputText } from "../../components/inputs";

import loginUserLogo from "../../assets/icon/loginUser.png";

import { useDispatch } from "react-redux";
import { Separator } from "../../components/separator";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { ResetFooterComponent } from "../../components/base/resetFooter";
import { resetUserAction } from "../../store/users/actions";
import { CustomModal } from "../../components/modal";
import { apiGet, apiPost } from "../../shared/ApiService";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

const ResetPage = () => {
  const recoverEndpoint = `${API_URL}/${Endpoints.RECOVER}`;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recoverEmail, setRecoverEmail ] = useState<undefined | string >( undefined );
  const [username, setUsername] = useState();

  const formRef = React.createRef<FormInstance>();

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (!username) {
        throw "";
      }
      message.success("Se ha enviado un email con la nueva contraseña.");
      emptyField();
      await dispatch(resetUserAction({ username: username }));
    } catch (error) {
      message.error("Ha ocurrido un error. Intentélo más tarde");
    } finally {
      setIsLoading(false);
    }
  };

  const getEmailFromUser = async () => {

    if (username) {
      const response = await apiPost({ url: recoverEndpoint, body:{ username } });
      setRecoverEmail( response.email || "<Usuario no encontrado>" );
      setIsModalVisible(true);
    } else {
      message.error(
        "Debe ingresar un nombre de usuario para recuperar."
      );
    }
  };

  const emptyField = () => {
    setRecoverEmail(undefined);
    if (formRef && formRef.current) {
      formRef.current.validateFields();
    }
  };

  return (
    <AuthenticatedRoute path={Paths.RESET} login withHeader withLogo>
      <>
        <Separator marginTop={10}>
          <Col span={24} style={{ margin: "40px 0" }}>
            <p
              className="custom-span-text"
              style={{ textAlign: "center", fontSize: 18 }}
            >
              Recuperación de contraseña, por favor ingrese su usuario.
            </p>
          </Col>
        </Separator>
        <Separator marginTop={10}>
          <Form
            name="basic"
            initialValues={undefined}
            onFinish={handleSubmit}
            layout="vertical"
            ref={formRef}
          >
            <FormItemComponent
              inputName={"username"}
              rules={[{ name: "required" }]}
              message={`Por favor, ingresá tu usuario.`}
            >
              <InputText
                enabled
                required
                prefix={
                  <Image
                    style={{ objectFit: "contain" }}
                    src={loginUserLogo}
                    preview={false}
                  />
                }
                onChange={(text) => setUsername(text)}
                style={{ height: 45 }}
                showPlaceholder
                type={"text"}
                name={"username"}
                placeholder={"Usuario"}
              />
            </FormItemComponent>

            <ResetFooterComponent
              handleSubmit={getEmailFromUser}
              isSubmitting={isLoading}
            />

            <CustomModal
              showOk
              showCancel
              showOkText={"Ok"}
              showCancelText={"Cancelar"}
              closable={false}
              isModalVisible={isModalVisible && typeof recoverEmail == 'string' }
              handleOk={handleSubmit}
              handleCancel={() => setIsModalVisible(false)}
              contentMessage={
                <p
                  className="custom-span-text"
                  style={{ textAlign: "center", fontSize: 18 }}
                >
                  La nueva contraseña será enviada a:<br/> 
                  <b>{recoverEmail}</b> <br/> ¿Desea
                  continuar?
                </p>
              }
            />
          </Form>
        </Separator>
      </>
    </AuthenticatedRoute>
  );
};

export default ResetPage;
