import { Modal } from "antd";
import React from "react";
import ClossableIcon from "../../assets/icon/modalIcons/clossableIcon.png";
import { API_URL } from "../../config/general-config";
import { ModalImageProps } from "../../shared/types";

export default function (props: ModalImageProps) {
  const { clossable, isVisible, imageUrl, onHide } = props;

  return (
    <Modal
      visible={!!isVisible}
      destroyOnClose
      closable={clossable}
      closeIcon={
        <img
          src={ClossableIcon}
          alt="clossable icon"
          style={{ marginTop: -92, marginLeft: 30 }}
        />
      }
      footer={null}
      onCancel={onHide}
    >
      <div
        style={{
          textAlign: "center",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <img
          src={`${API_URL}/public/images/productos/${imageUrl}`}
          alt={`${imageUrl} image`}
          style={{ objectFit: "contain", width: "100%", height: "100%" }}
        />
      </div>
    </Modal>
  );
}
