import { message, Modal } from "antd";
import FileSaver from "file-saver";
import { ConfirmDialogComponent } from "../components/confirmDialog";
import { API_URL, LOCAL_STORAGE_JWT } from "../config/general-config";
import { getToken, unauthorizedCallback } from "../core/auth/AuthService";

import {
  ConfirmDialogTypeEnum,
  BaseApiParams,
  DeleteParams,
  GetParams,
  PostDownloadParams,
  PostParams,
  PutParams,
} from "./types";

const genericRequest = async (
  server: string,
  endpoint: string,
  method: string,
  body: any,
  unauthorizedCallback: any
) => {
  const url = `${API_URL}/${endpoint}`;
  switch (method) {
    case "POST":
      return apiPost({ url, body, unauthorizedCallback });
    default:
      return apiGet({ url, unauthorizedCallback });
  }
};

const apiGet = async (params: GetParams) => {
  try {
    const { url } = params;
    const response = await fetch(url, {
      method: "GET",
      headers: getHeaders(),
    });

    return processJsonResponse(params, response);
  } catch (error) {
    ConfirmDialogComponent({
      message: "Se ha producido un error inesperado",
      type: ConfirmDialogTypeEnum.Error,
    });
    return {};
  }
};

const apiDelete = async (params: DeleteParams) => {
  try {
    const { url } = params;
    const response = await fetch(url, {
      method: "DELETE",
      headers: getHeaders(),
    });

    return processJsonResponse(params, response);
  } catch (error) {
    ConfirmDialogComponent({
      message: "Se ha producido un error inesperado",
      type: ConfirmDialogTypeEnum.Error,
    });
    return {};
  }
};

const apiPost = async (params: PostParams) => {
  const { url } = params;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(params.body),
    headers: getHeaders(),
  });

  return processJsonResponse(params, response);
};

const apiPut = async (params: PutParams, stringify = true) => {
  const { url } = params;
  const response = await fetch(url, {
    method: "PUT",
    body: stringify ? JSON.stringify(params.body) : params.body,
    headers: getHeaders(),
  });

  return processJsonResponse(params, response);
};

const apiPostDownload = async (params: PostDownloadParams) => {
  try {
    const { url } = params;

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(params.body),
      headers: getHeaders(),
    });

    return processFileResponse(params, response);
  } catch (error) {
    ConfirmDialogComponent({
      type: ConfirmDialogTypeEnum.Error,
      message: "Se ha producido un error inesperado",
    });
    return {};
  }
};

const getHeaders = () => {
  const jwtToken = getToken();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  if (jwtToken) {
    headers.append("Authorization", `Bearer ${jwtToken}`);
  }

  return headers;
};

const getHeadersWithoutContentType = () => {
  const jwtToken = getToken();
  const headers = new Headers();
  if (jwtToken) {
    headers.append("Authorization", `Bearer ${jwtToken}`);
  }

  return headers;
};

const processJsonResponse = async (
  params: BaseApiParams,
  response: Response
) => {
  const contentType = response.headers.get("content-type");
  if (
    (response.status === 200 || response.status === 204) &&
    params.showSuccesMessage
  ) {
    message.success("Accion realizada correctamente!", 8);
  }

  if (
    response.status === 200 &&
    contentType?.indexOf("application/json") !== -1
  ) {
    return await response.json();
  } else if (response.status === 200) {
    return await response.text();
  }

  return await processResponse(params, response);
};

const processFileResponse = async (
  params: PostDownloadParams,
  response: Response
) => {
  const { fileName, extension } = params;

  if (response.status === 200) {
    const blob = await response.blob();

    FileSaver.saveAs(blob, `${fileName.toLocaleLowerCase()}.${extension}`);
  }

  return await processResponse(params, response);
};

const getErrorFromServiceResponse = async (response: Response) => {
  const objectResponse = await response.json();
  if (
    objectResponse &&
    objectResponse.errors &&
    Array.isArray(objectResponse.errors) &&
    objectResponse.errors.length
  ) {
    const firstError = objectResponse.errors[0];
    return firstError.errorMessage || firstError.errorCode;
  }

  return null;
};

const processResponse = async (params: BaseApiParams, response: Response) => {
  let message = undefined;

  switch (response.status) {
    case 400:
      const errorMessage =
        (await getErrorFromServiceResponse(response)) ||
        "Se ha producido un error inesperado";

      throw new Error(errorMessage);
    case 422:
      unauthorizedCallback();
      console.error("Unauthorized [422]");
      break;

    case 401:
      message = "Se ha producido un error. Verifique sus credenciales";
      console.error("Unauthorized [401]");
      params.unauthorizedCallback();
      break;

    case 404:
      throw new Error("Se ha producido un error inesperado");

    case 415:
    case 405:
    case 500:
      message = "Se ha producido un error inesperado";
      if (params && params.unauthorizedCallback) {
        params.unauthorizedCallback();
      }
      break;
  }

  if (message) {
    ConfirmDialogComponent({
      type: ConfirmDialogTypeEnum.Error,
      message: message,
    });
  }

  return {};
};

export {
  apiGet,
  apiPost,
  apiDelete,
  apiPostDownload,
  genericRequest,
  apiPut,
  getHeadersWithoutContentType,
  getHeaders,
};
