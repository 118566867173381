import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_ORDER_TO_QUOTE,
  CLEAN_ORDER_CREATION_STATE,
  GET_ORDERS_SEARCH,
  GET_PRICES_LIST,
  SAVE_ORDER,
  SET_ORDER_TO_CANCEL,
} from "./actionTypes";

import {
  addOrderToQuoteFailAction,
  addOrderToQuoteSuccessAction,
  cleanOrderStateActionFail,
  cleanOrderStateActionSuccess,
  getOrdersSearchFail,
  getOrdersSearchSuccess,
  getPricesListFail,
  getPricesListSuccess,
  saveOrderFail,
  saveOrderSuccess,
  setOrderToCancelFail,
  setOrderToCancelSuccess,
} from "./actions";

import { apiGet, apiPost, apiPut } from "../../shared/ApiService";
import { OrdersProps, QuoteAndOrderDataProps } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { unauthorizedCallback } from "../../core/auth/AuthService";

function* onGetOrdersForSearch(payload: any) {
  try {
    const response: OrdersProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.orderUrl,
          unauthorizedCallback: () => {
            unauthorizedCallback();
          },
        })
      );
    });
    yield put(getOrdersSearchSuccess(response));
  } catch (error: any) {
    yield put(getOrdersSearchFail(error.response));
  }
}

function* onAddOrderQuote(payload: any) {
  try {
    const response: object = yield call(() =>
      Promise.resolve(
        apiGet({ url: payload.orderQuote, unauthorizedCallback: () => {} })
      )
    );
    yield put(addOrderToQuoteSuccessAction(response));
  } catch (error: any) {
    yield put(addOrderToQuoteFailAction(error.response));
  }
}

function* onCleanProductState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanOrderStateActionSuccess());
  } catch (error: any) {
    yield put(cleanOrderStateActionFail(error.response));
  }
}

function* onGetProductsList() {
  try {
    const response: object = yield call(() =>
      Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRICES_LIST}`,
          unauthorizedCallback: () => {},
        })
      )
    );
    yield put(getPricesListSuccess(response));
  } catch (error: any) {
    yield put(getPricesListFail(error));
  }
}

function* onSaveOrder(payload: any) {
  try {
    const response: QuoteAndOrderDataProps = yield call(() => {
      const id = payload.order && payload.order._id;
      if (id) {
        return Promise.resolve(
          apiPut({
            url: `${API_URL}/${Endpoints.SAVE_ORDER}/${id}`,
            body: payload.order,
            unauthorizedCallback: () => {},
          })
        );
      } else {
        return Promise.resolve(
          apiPost({
            url: `${API_URL}/${Endpoints.SAVE_ORDER}`,
            body: payload.order,
            unauthorizedCallback: () => {},
          })
        );
      }
    });
    yield put(saveOrderSuccess(response));
  } catch (error: any) {
    yield put(saveOrderFail(error.response));
  }
}

function* onSetOrderToCancel(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(setOrderToCancelSuccess(payload.order));
  } catch (error: any) {
    yield put(setOrderToCancelFail(error.response));
  }
}

function* OrdersSaga() {
  yield takeLatest(GET_ORDERS_SEARCH, onGetOrdersForSearch);
  yield takeLatest(CLEAN_ORDER_CREATION_STATE, onCleanProductState);
  yield takeLatest(ADD_ORDER_TO_QUOTE, onAddOrderQuote);
  yield takeLatest(GET_PRICES_LIST, onGetProductsList);
  yield takeLatest(SAVE_ORDER, onSaveOrder);
  yield takeLatest(SET_ORDER_TO_CANCEL, onSetOrderToCancel);
}

export default OrdersSaga;
