export const GET_ORDERS_SEARCH = "GET_ORDER_SEARCH";
export const GET_ORDERS_SEARCH_SUCCESS = "GET_ORDERS_SEARCH_SUCCESS";
export const GET_ORDERS_SEARCH_FAIL = "GET_ORDERS_SEARCH_FAIL";

export const ADD_ORDER_TO_QUOTE = "ADD_ORDER_TO_QUOTE";
export const ADD_ORDER_TO_QUOTE_SUCCESS = "ADD_ORDER_TO_QUOTE_SUCCESS";
export const ADD_ORDER_TO_QUOTE_FAIL = "ADD_ORDER_TO_QUOTE_FAIL";

export const CLEAN_ORDER_CREATION_STATE = "CLEAN_ORDER_CREATION_STATE";
export const CLEAN_ORDER_CREATION_STATE_FAIL =
  "CLEAN_ORDER_CREATION_STATE_FAIL";
export const CLEAN_ORDER_CREATION_STATE_SUCCESS =
  "CLEAN_ORDER_CREATION_STATE_SUCCESS";

export const GET_PRICES_LIST = "GET_PRICES_LIST";
export const GET_PRICES_LIST_SUCCESS = "GET_PRICES_LIST_SUCCESS";
export const GET_PRICES_LIST_FAIL = "GET_PRICES_LIST_FAIL";

export const SAVE_ORDER = "SAVE_ORDER";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_FAIL = "SAVE_ORDER_FAIL";

export const SET_ORDER_TO_CANCEL = "SET_ORDER_TO_CANCEL";
export const SET_ORDER_TO_CANCEL_SUCCESS = "SET_ORDER_TO_CANCEL_SUCCESS";
export const SET_ORDER_TO_CANCEL_FAIL = "SET_ORDER_TO_CANCEL_FAIL";
