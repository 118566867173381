import { takeLatest, put, call } from "redux-saga/effects";

import { GET_DATER } from "./actionTypes";
import { getDaterFail, getDaterSuccess } from "./action";
import { apiGet } from "../../shared/ApiService";

function* onGetDater(payload: any) {
  try {
    const response: any[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.daterUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getDaterSuccess(response));
  } catch (error: any) {
    yield put(getDaterFail(error.response));
  }
}

function* DaterSaga() {
  yield takeLatest(GET_DATER, onGetDater);
}

export default DaterSaga;
