import { takeLatest, put, call, takeEvery } from "redux-saga/effects";

import {
  GET_PRODUCTS_SELECT,
  GET_PRODUCTS_SEARCH,
  SET_PRODUCT,
  GET_PRODUCTS_ATTR,
  GET_PRODUCTS_CHILD,
  GET_PRODUCTS_COMPLEMENTS,
  CLEAN_PRODUCT_CREATION_STATE,
  ADD_PRODUCT_TO_QUOTE,
  GET_PRODUCT_PRICE_LIST,
  PRODUCT_SEARCH_BY_ID,
  SET_COMPLEMENTS,
  GET_PRODUCT_COMPLETE_DATA,
  UPDATE_QUALITATIVE_SELECTED_VALUE,
  ADD_PRODUCT_TO_QUOTE_TO_DELETE,
  DELETE_PRODUCT_FROM_ORDER,
} from "./actionTypes";

import {
  getProductsSearchFail,
  getProductsSelectFail,
  setProductFail,
  getProductsSearchSuccess,
  getProductsSelectSuccess,
  setProductSuccess,
  getProductsAttrSuccess,
  getProductsAttrFail,
  getProductsChildSuccess,
  getProductsChildFail,
  getProductsComplementSuccess,
  getProductsComplementFail,
  cleanProductStateActionSuccess,
  cleanProductStateActionFail,
  addProductToQuoteSuccess,
  addProductToQuoteFail,
  getProductPriceListSuccess,
  getProductPriceListFail,
  getProductsChildAppendSuccess,
  getProductsChildAppendFail,
  getProductByIdSuccess,
  getProductByIdFail,
  setComplementsFail,
  setComplementsSuccess,
  getProductCompleteDataSuccess,
  getProductCompleteDataFail,
  updateQualitativeSelectedValueSuccess,
  updateQualitativeSelectedValueFail,
  addProductToQuoteToDeleteSuccess,
  addProductToQuoteToDeleteFail,
  deleteProductOrderSuccess,
  deleteProductOrderFailed,
} from "./actions";

import { apiGet, apiPost } from "../../shared/ApiService";
import {
  OrdersDataProps,
  ProductDataProps,
  ProductPriceDataProps,
  ProductsProps,
} from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { IProductAttr } from "../../core/models/attributes";
import { IAppendChildData, IProductChildData, IProductComplements } from "../../core/models/products";

function* onGetProductsForSelect(payload: any) {
  try {
    const response: IProductAttr = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRODUCT_SELECT}/${payload.rubroId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductsSelectSuccess(response));
  } catch (error: any) {
    yield put(getProductsSelectFail(error.response));
  }
}

function* onGetProductsForSearch(payload: any) {
  try {
    const response: ProductsProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.productUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductsSearchSuccess(response));
  } catch (error: any) {
    yield put(getProductsSearchFail(error.response));
  }
}

function* onGetProductsAttr(payload: any) {
  try {
    const response: IProductAttr = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRODUCT_ATTR}/${payload.productId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductsAttrSuccess(response));
  } catch (error: any) {
    yield put(getProductsAttrFail(error.response));
  }
}

function* onGetProductsChild( payload: { type:string, productId:number, appendData:boolean, description:string , groupid:number } ) {

  try {
    const response: IProductChildData[] = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRODUCT_CHILD}/${payload.productId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    if (payload.appendData) {
      const newResponse: IAppendChildData = {
        value: payload.productId,
        description: payload.description,
        items: response,
        groupid: payload.groupid
      };
      yield put(getProductsChildAppendSuccess(newResponse));
    } else {
      yield put(getProductsChildSuccess(response));
    }
  } catch (error: any) {
    if (payload.appendData) {
      yield put(getProductsChildAppendFail(error.response));
    } else {
      yield put(getProductsChildFail(error.response));
    }
  }
}

function* onGetProductsComplements(payload: any) {
  try {
    const response: IProductComplements = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRODUCT_COMPLEMENTS}/${payload.productId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductsComplementSuccess(response));
  } catch (error: any) {
    yield put(getProductsComplementFail(error.response));
  }
}

function* onSetComplements(payload: any) {
  try {
    yield put(setComplementsSuccess(payload.complements));
  } catch (error: any) {
    yield put(setComplementsFail(error.response));
  }
}

function* onSetProduct(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(setProductSuccess(payload.product));
  } catch (error: any) {
    yield put(setProductFail(error.response));
  }
}

function* onAddProductToQuote(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(addProductToQuoteSuccess(payload.productQuote));
  } catch (error: any) {
    yield put(addProductToQuoteFail(error.response));
  }
}

function* onAddProductToQuoteToDelete(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(addProductToQuoteToDeleteSuccess(payload.productQuote));
  } catch (error: any) {
    yield put(addProductToQuoteToDeleteFail(error.response));
  }
}

function* onUpdateQualitativeSelectedValue(payload: any) {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(updateQualitativeSelectedValueSuccess(payload.productQuote));
  } catch (error: any) {
    yield put(updateQualitativeSelectedValueFail(error.response));
  }
}

function* onCleanProductState() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanProductStateActionSuccess());
  } catch (error: any) {
    yield put(cleanProductStateActionFail(error.response));
  }
}

function* getPriceListAction(payload: any) {
  try {
    const response: ProductPriceDataProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRICES_LIST}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductPriceListSuccess(response));
  } catch (error: any) {
    yield put(getProductPriceListFail(error.response));
  }
}

function* onSearchProductById(payload: any) {
  try {
    const response: ProductDataProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.PRODUCT_SEARCH_BY_ID}/${payload.productId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductByIdSuccess(response));
  } catch (error: any) {
    yield put(getProductByIdFail(error.response));
  }
}

function* getProductCompleteData(payload: any) {
  try {
    const response: ProductDataProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.GET_PRODUCT_COMPLETE_DATA}/${payload.productId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getProductCompleteDataSuccess(response));
  } catch (error: any) {
    yield put(getProductCompleteDataFail(error.response));
  }
}

function* deleteProductOrder(payload:any)
{
  const { payload:body } = payload;
  try 
  {
    const response:OrdersDataProps = yield call(() => {
      return Promise.resolve(
        apiPost({
          url:`${API_URL}/${Endpoints.DELETE_PRODUCT_FROM_ORDER}`,
          body: body,
          unauthorizedCallback: () => {},
        })
      );
    });

    yield put(deleteProductOrderSuccess(response));
  } catch (error: any) {
    yield put(deleteProductOrderFailed(error.response));
  }
}

function* ProductSaga() {
  yield takeLatest(GET_PRODUCTS_ATTR, onGetProductsAttr);
  /*
    OJO 
    GET_PRODUCTS_CHILD
    onGetProductsChild
    este se cambia a takeEvery debido a que es el action que se ejecuta
    para definir los values de los productos hijos en los selectores de agregar/editar producto.
    ejemplo,
    si hay telas y mecanismos. y estos se montan y cambian sus estados en un mapeado. takelatest no 
    va a tomar en cuenta el llamado por tela, sino por el ultimo, mecanismos.
  */
  yield takeEvery(GET_PRODUCTS_CHILD, onGetProductsChild);
  yield takeEvery(UPDATE_QUALITATIVE_SELECTED_VALUE, onUpdateQualitativeSelectedValue );
  yield takeLatest(GET_PRODUCT_PRICE_LIST, getPriceListAction);
  yield takeLatest(GET_PRODUCTS_SELECT, onGetProductsForSelect);
  yield takeLatest(GET_PRODUCTS_SEARCH, onGetProductsForSearch);
  yield takeLatest(ADD_PRODUCT_TO_QUOTE, onAddProductToQuote);
  yield takeLatest(ADD_PRODUCT_TO_QUOTE_TO_DELETE, onAddProductToQuoteToDelete);
  yield takeLatest(GET_PRODUCTS_COMPLEMENTS, onGetProductsComplements);
  yield takeLatest(CLEAN_PRODUCT_CREATION_STATE, onCleanProductState);
  yield takeLatest(SET_PRODUCT, onSetProduct);
  yield takeLatest(PRODUCT_SEARCH_BY_ID, onSearchProductById);
  yield takeLatest(SET_COMPLEMENTS, onSetComplements);
  yield takeLatest(GET_PRODUCT_COMPLETE_DATA, getProductCompleteData);
  yield takeLatest(DELETE_PRODUCT_FROM_ORDER, deleteProductOrder);
}

export default ProductSaga;
