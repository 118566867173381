import { Button, Modal } from "antd";
import React from "react";
import { ModalFormProps } from "../../shared/types";

export default function CustomModal(props: ModalFormProps) {
  const {
    title,
    isModalVisible,
    handleCancel,
    isSubmitting,
    children,
    okText,
    cancelText,
  } = props;

  return (
    <Modal
      visible={isModalVisible}
      destroyOnClose
      closable={false}
      footer={null}
      onCancel={handleCancel}
    >
      <div style={{ textAlign: "center", margin: "14px 0 28px 0" }}>
        <span
          style={{
            fontWeight: 700,
            fontSize: 14,
            color: "#000000",
            letterSpacing: "0.25px",
          }}
        >
          {title}
        </span>
      </div>
      {children}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div style={{ width: 105, height: 45 }}>
          <Button
            className={`custom-button ${
              isSubmitting ? "custom-button-disabled" : ""
            }`}
            form="modal-form"
            key="submit"
            htmlType="submit"
            style={{
              borderRadius: "4px",
              backgroundColor: "#438EB9",
              height: "100%",
              color: "#FFFFFF",
            }}
            disabled={isSubmitting}
          >
            {okText}
          </Button>
        </div>
        <div style={{ width: 105, height: 45 }}>
          <Button
            className="custom-button"
            style={{
              borderRadius: "4px",
              backgroundColor: "#D15B48",
              borderColor: "#D15B48",
              height: "100%",
              color: "#FFFFFF",
            }}
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
