import * as types from "./actionTypes";

export const getOrdersSearchAction = (orderUrl: string) => {
  return {
    type: types.GET_ORDERS_SEARCH,
    orderUrl,
  };
};

export const getOrdersSearchSuccess = (orders: any) => {
  return { type: types.GET_ORDERS_SEARCH_SUCCESS, payload: orders };
};

export const getOrdersSearchFail = (error: any) => {
  return { type: types.GET_ORDERS_SEARCH_FAIL, payload: error };
};

export const addOrderToQuoteAction = (orderQuote: any) => {
  return {
    type: types.ADD_ORDER_TO_QUOTE,
    orderQuote,
  };
};

export const addOrderToQuoteSuccessAction = (order: any) => {
  return {
    type: types.ADD_ORDER_TO_QUOTE_SUCCESS,
    payload: order,
  };
};

export const addOrderToQuoteFailAction = (error: any) => {
  return {
    type: types.ADD_ORDER_TO_QUOTE_FAIL,
    payload: error,
  };
};

export const cleanOrderStateAction = () => {
  return {
    type: types.CLEAN_ORDER_CREATION_STATE,
  };
};

export const cleanOrderStateActionFail = (error: any) => {
  return {
    type: types.CLEAN_ORDER_CREATION_STATE_FAIL,
    payload: error,
  };
};

export const cleanOrderStateActionSuccess = () => {
  return {
    type: types.CLEAN_ORDER_CREATION_STATE_SUCCESS,
  };
};

export const getPricesList = () => {
  return {
    type: types.GET_PRICES_LIST,
  };
};

export const getPricesListSuccess = (pricesList: any) => {
  return {
    type: types.GET_PRICES_LIST_SUCCESS,
    payload: pricesList,
  };
};

export const getPricesListFail = (error: any) => {
  return {
    type: types.GET_PRICES_LIST_FAIL,
    payload: error,
  };
};

export const saveOrderAction = (order: any) => {
  return {
    type: types.SAVE_ORDER,
    order,
  };
};

export const saveOrderSuccess = (pricesList: any) => {
  return {
    type: types.SAVE_ORDER_SUCCESS,
    payload: pricesList,
  };
};

export const saveOrderFail = (error: any) => {
  return {
    type: types.SAVE_ORDER_FAIL,
    payload: error,
  };
};

export const setOrderToCancelAction = (order: any) => {
  return {
    type: types.SET_ORDER_TO_CANCEL,
    order,
  };
};

export const setOrderToCancelSuccess = (order: any) => {
  return {
    type: types.SET_ORDER_TO_CANCEL_SUCCESS,
    payload: order,
  };
};

export const setOrderToCancelFail = (error: any) => {
  return {
    type: types.SET_ORDER_TO_CANCEL_FAIL,
    payload: error,
  };
};
