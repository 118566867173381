import { Paths } from "../../../config/paths";

import drafts from "../../../assets/icon/dashboard/drafts.png";
import quotes from "../../../assets/icon/dashboard/quotes.png";
import newQuote from "../../../assets/icon/dashboard/newQuote.png";
import newOrder from "../../../assets/icon/dashboard/newOrder.png";
import orders from "../../../assets/icon/dashboard/orders.png";
import dollar from "../../../assets/icon/dashboard/dollar.png";
import settings from "../../../assets/icon/dashboard/settings.png";
import usefulFiles from "../../../assets/icon/dashboard/usefulFiles.png";
import Dater from "../../../assets/icon/dashboard/dater.png";
import { FormTypeEnum } from "../../../shared/types";
import { hasPermission } from "../../../core/auth/AuthService";

export const DashboardMenu = [
  {
    path: Paths.NEWQUOTATION,
    name: "Nueva Cotización",
    icon: newQuote,
    withIcon: true,
    show: true
  },
  {
    path: Paths.QUOTATION,
    name: "Mis Cotizaciones",
    icon: quotes,
    withIcon: true,
    marginIcon: -2.5,
    show: true
  },
  {
    path: Paths.NEWORDER,
    name: "Nuevo Pedido",
    icon: newOrder,
    withIcon: true,
    show: true
  },
  {
    path: `${Paths.ORDER}/${FormTypeEnum.Order}`,
    name: "Mis Pedidos",
    icon: orders,
    withIcon: true,
    marginIcon: -2.5,
    show: true
  },
  {
    path: `${Paths.ORDER}/${FormTypeEnum.Draft}`,
    name: "Mis Borradores",
    icon: drafts,
    withIcon: true,
    show: true
  },
  {
    path: Paths.CONFIGURATION,
    name: "Mi Configuración",
    icon: settings,
    withIcon: true,
    show: true
  },
  {
    path: Paths.BALANCE,
    name: "Mi Saldo",
    icon: dollar,
    withIcon: true,
    show: true
  },
  {
    path: Paths.USEFULFILES,
    name: "Descargas",
    icon: usefulFiles,
    withIcon: true,
    show: hasPermission('MENU_ADMINISTRACION_DESCARGAS')
  },
  {
    path: Paths.DATER,
    name: "Fechador",
    icon: Dater,
    withIcon: true,
    show: hasPermission('MENU_FECHADOR')
  },
].filter((row)=> {
  return row.show;
});
