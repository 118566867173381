export enum Paths {
  BALANCE = "/balance",
  CANCELORDER = "/orderToCancel",
  CLIENTS = "/clients",
  COMPLEMENTS = "/complements",
  CONFIGURATION = "/configuration",
  DATER = "/dater",
  LOGIN = "/login",
  LOGOUT = "/logout",
  NEWORDER = "/neworder",
  NEWPRODUCT = "/newproduct",
  NEWQUOTATION = "/newquotation",
  ORDER = "/order",
  ORDERDRAFTS = "/orderdrafts",
  PAGENOTFOUNT = "/pagenotfount",
  PRODUCT = "/product",
  PRODUCTCOMPLEMENT = "/productcomplement",
  PRODUCTDETAIL = "/productdetail",
  PRODUCTDETAILLIST = "/productdetaillist",
  PRODUCTREFERENCE = "/productreference",
  QUOTATION = "/quotation",
  ROOT = "/",
  RESET = "/reset",
  USEFULFILES = "/usefulfiles",
}
