import { Button, Col, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HeaderComponent } from "../../components/base";
import { CustomModal } from "../../components/modal";
import { Separator } from "../../components/separator";
import { Endpoints } from "../../config/endpoints";
import { API_URL } from "../../config/general-config";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { ReduxType, UserInfoI } from "../../shared/types";
import {
  getResumeAction,
  getUserInfoAction,
} from "../../store/balance/actions";
import okModalIcon from "../../assets/icon/modalIcons/ok.png";
import NumberFormat from 'react-number-format';


const BalancePage = () => {
  const [userInfo, setUserInfo] = useState<UserInfoI | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { balanceData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!balanceData.user) {
      doRequest();
    } else {
      const userinfo =
        typeof balanceData.user === "string"
          ? JSON.parse(balanceData.user)
          : balanceData.user;
      setUserInfo(userinfo);
    }
  }, [balanceData.user]);

  const doRequest = async () => {
    const url = `${API_URL}/${Endpoints.USER}`;
    await dispatch(getUserInfoAction(url));
  };

  const onRequestResume = async () => {
    try {
      const id = userInfo ? `/${userInfo._id}` : "";
      const url = `${API_URL}/${Endpoints.CLIENTS}${id}${Endpoints.BALANCE_RESUME}`;
      await dispatch(getResumeAction(url));
      setIsModalVisible(true);
    } catch (e: any) {
      message.error(e);
    }
  };

  return (
    <AuthenticatedRoute
      path={Paths.BALANCE}
      withHeader
      goBack={() => navigate(Paths.ROOT)}
      headerNavigationName={"Mi Saldo"}
    >
      {!isLoading && (
        <>
          <CustomModal
            closable={false}
            isModalVisible={isModalVisible}
            handleOk={() => setIsModalVisible(false)}
            handleCancel={() => setIsModalVisible(false)}
            image={okModalIcon}
            contentMessage={"Se ha solicitado el resumen de cuenta con éxito."}
          />
          <Separator marginTop={10}>
            <Col span={24} style={{ margin: "50px 0" }}>
              <p
                className="custom-span-text"
                style={{ textAlign: "center", fontSize: 18 }}
              >
                El saldo del cliente {userInfo && userInfo.nombrecomercial}, al
                dia&nbsp;
                {new Date(Date.now()).toLocaleDateString("es-AR")} es de 
                { userInfo && userInfo.saldo ? 
                                              <NumberFormat 
                                                value={userInfo.saldo} 
                                                displayType={'text'} 
                                                thousandSeparator={true}
                                                decimalScale={2} 
                                                fixedDecimalScale={true}
                                                prefix={'$'} />  : ' $0.00'}.
              </p>
            </Col>
          </Separator>
          <Separator marginTop={50}>
            <Button
              type="primary"
              htmlType="submit"
              className={"custom-button  custom-button-blue"}
              onClick={onRequestResume}
            >
              Solicitar resumen
            </Button>
          </Separator>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default BalancePage;
