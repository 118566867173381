import { all, fork } from "redux-saga/effects";

import UserInfoSaga from "./balance/saga";
import ClientSaga from "./clients/saga";
import ConfigurationSaga from "./configuration/saga";
import ProductSaga from "./products/saga";
import RubroSaga from "./rubro/saga";
import UserSaga from "./users/saga";
import OrdersSaga from "./orders/saga";
import QuoteSaga from "./quotes/saga";
import UsefulFileSaga from "./usefulFiles/saga";
import DaterSaga from "./Dater/saga";
import AttributeSaga from "./attributes/saga";

export default function* rootSaga() {
  yield all([
    fork(AttributeSaga),
    fork(UserSaga),
    fork(ClientSaga),
    fork(RubroSaga),
    fork(ProductSaga),
    fork(OrdersSaga),
    fork(ConfigurationSaga),
    fork(UserInfoSaga),
    fork(QuoteSaga),
    fork(UsefulFileSaga),
    fork(DaterSaga),
  ]);
}
