import * as _ from "lodash";
import { IProductAttr } from "../../core/models/attributes";
import { AttributeTypes } from "../../shared/types";
import * as types from "./actionTypes";

const initialState = {
  productsSelect: undefined,
  productsSearch: undefined,
  product: undefined,
  productById: undefined,
  productChild: [],
  productAppendChild: {},
  productComplements: undefined,
  productQuote: [],
  productQuoteToDelete: [],
  attr: undefined,
  quoteProduct: [],
  productPriceList: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const ProductReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;

  switch (actionType) {
    case types.SET_PRODUCT:
    case types.GET_PRODUCTS_CHILD:
    case types.GET_PRODUCTS_SELECT:
    case types.GET_PRODUCTS_SEARCH:
    case types.ADD_PRODUCT_TO_QUOTE:
    case types.UPDATE_QUALITATIVE_SELECTED_VALUE:
    case types.GET_PRODUCTS_COMPLEMENTS:
    case types.CLEAN_PRODUCT_CREATION_STATE:
    case types.GET_PRODUCT_PRICE_LIST:
    case types.PRODUCT_SEARCH_BY_ID:
    case types.GET_PRODUCT_COMPLETE_DATA:
    case types.SET_COMPLEMENTS:
    case types.ADD_PRODUCT_TO_QUOTE_TO_DELETE:
    case types.DELETE_PRODUCT_FROM_ORDER:
      state = { ...state, loading: true };
      break;

    case types.SET_PRODUCT_SUCCESS:
      if (actionPayload.atributos) {
        state = {
          ...state,
          attr: actionPayload.atributos,
          productChild: actionPayload.productosHijos,
          productComplements: actionPayload.complementosNecesarios,
          product: actionPayload,
          loading: false,
        };
      } else {
        state = { ...state, product: actionPayload, loading: false };
      }

      break;

    case types.SET_COMPLEMENTS_SUCCESS:
      state = { ...state, productComplements: actionPayload, loading: false };
      break;

    case types.GET_PRODUCTS_SELECT_SUCCESS:
      state = { ...state, productsSelect: actionPayload, loading: false };
      break;
    case types.GET_PRODUCTS_SEARCH_SUCCESS:
      state = { ...state, productsSearch: actionPayload, loading: false };
      break;
    case types.GET_PRODUCTS_ATTR_SUCCESS:
      state = { 
        ...state, 
        attr: actionPayload.map(( attr:IProductAttr ) => {
          if(attr.tipodeatributo_id == AttributeTypes.cuantitativoSimple){
            attr.base = 1;
          }
          if(attr.tipodeatributo_id == AttributeTypes.cuantitativoCompuesto){
            attr.base = 1;
            attr.altura = 1;
          }

          return attr;
        }),
        loading: false };
      break;
    case types.GET_PRODUCTS_CHILD_SUCCESS:
      state = {
        ...state,
        productChild: actionPayload,
        loading: false,
      };
      break;
    case types.GET_PRODUCTS_COMPLEMENTS_SUCCESS:
      state = { ...state, productComplements: actionPayload, loading: false };
      break;

    case types.CLEAN_PRODUCT_CREATION_STATE_SUCCESS:
      state = {
        ...state,
        product: undefined,
        productById: undefined,
        attr: undefined,
        productAppendChild: [],
        productQuoteToDelete: [],
        productComplements: undefined,
        loading: false,
        productPriceList: undefined,
        productChild: [],
        productsSelect:undefined
      };
      break;

    case types.ADD_PRODUCT_TO_QUOTE_SUCCESS:
      state = { ...state, productQuote: actionPayload, loading: false };
      break;

    case types.GET_PRODUCT_PRICE_LIST_SUCCESS:
      state = { ...state, productPriceList: actionPayload, loading: false };
      break;

    case types.DELETE_CHILD_PRODUCT_IN_APPEND:
      state = { 
        ...state,
        productAppendChild: _.pickBy(state.productAppendChild, (key:any)=> {
          return key.groupid != actionPayload;
        })  
      };
      break;

    case types.GET_PRODUCTS_CHILD_APPEND_SUCCESS:
      const arr: any = {
        ...state.productAppendChild,
        [actionPayload.description]: actionPayload,
      };

      state = {
        ...state,
        productAppendChild: arr,
        loading: false,
      };
      break;

    case types.SET_VAL_ATTR_PRODUCT_DATA:
      state = { ...state, attr: actionPayload
      };
      break;

    case types.PRODUCT_SEARCH_BY_ID_SUCCESS:
      state = { ...state, productById: actionPayload, loading: false };
      break;

    case types.GET_PRODUCT_COMPLETE_DATA_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.UPDATE_QUALITATIVE_SELECTED_VALUE_SUCCESS:
      state = { ...state, productQuote: actionPayload, loading: false };
      break;

    case types.ADD_PRODUCT_TO_QUOTE_TO_DELETE_SUCCESS:
      state = { ...state, productQuoteToDelete: actionPayload, loading: false };
      break;
    case types.DELETE_PRODUCT_FROM_ORDER_SUCCESS:
      state = { ...state, loading: false };
      break;

    case types.SET_PRODUCT_FAIL:
    case types.GET_PRODUCTS_CHILD_FAIL:
    case types.GET_PRODUCTS_SEARCH_FAIL:
    case types.GET_PRODUCTS_SELECT_FAIL:
    case types.ADD_PRODUCT_TO_QUOTE_FAIL:
    case types.UPDATE_QUALITATIVE_SELECTED_VALUE_FAIL:
    case types.GET_PRODUCT_PRICE_LIST_FAIL:
    case types.GET_PRODUCTS_COMPLEMENTS_FAIL:
    case types.CLEAN_PRODUCT_CREATION_STATE_FAIL:
    case types.GET_PRODUCTS_CHILD_APPEND_FAIL:
    case types.PRODUCT_SEARCH_BY_ID_FAIL:
    case types.GET_PRODUCT_COMPLETE_DATA_FAIL:
    case types.ADD_PRODUCT_TO_QUOTE_TO_DELETE_FAIL:
    case types.DELETE_PRODUCT_FROM_ORDER_FAILED:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }
  return state;
};

export default ProductReducer;
