import * as types from "./actionTypes";

export const saveQuoteAction = (quote: any) => {
  return {
    type: types.SAVE_QUOTE,
    quote,
  };
};

export const saveQuoteFail = (error: any) => {
  return {
    type: types.SAVE_QUOTE_FAIL,
    payload: error,
  };
};

export const saveQuoteSuccess = (quote: any) => {
  return {
    type: types.SAVE_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const getQuoteSearchAction = (quotationUrl: string) => {
  return {
    type: types.GET_QUOTE_SEARCH,
    quotationUrl,
  };
};

export const getQuoteSearchFail = (error: any) => {
  return {
    type: types.GET_QUOTE_SEARCH_FAIL,
    payload: error,
  };
};

export const getQuoteSearchSuccess = (quotations: any) => {
  return {
    type: types.GET_QUOTE_SEARCH_SUCCESS,
    payload: quotations,
  };
};

export const addQuoteToQuoteAction = (quote: any) => {
  return {
    type: types.ADD_QUOTE_TO_QUOTE,
    quote,
  };
};

export const addQuoteToQuoteSuccess = (quote: any) => {
  return {
    type: types.ADD_QUOTE_TO_QUOTE_SUCCESS,
    payload: quote,
  };
};

export const addQuoteToQuoteFail = (error: any) => {
  return {
    type: types.ADD_QUOTE_TO_QUOTE_FAIL,
    payload: error,
  };
};

export const cleanQuoteToQuoteAction = () => {
  return {
    type: types.CLEAN_QUOTE_TO_QUOTE,
  };
};

export const cleanQuoteToQuoteSuccess = () => {
  return {
    type: types.CLEAN_QUOTE_TO_QUOTE_SUCCESS,
  };
};

export const cleanQuoteToQuoteFail = (error: any) => {
  return {
    type: types.CLEAN_QUOTE_TO_QUOTE_FAIL,
    payload: error,
  };
};
