import React, { useState } from "react";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { cleanClientStateAction } from "../../store/clients/actions";
import { QuoteAndOrderMainForm } from "../../components/forms/quoteAndOrderMainForm";
import { FormTypeEnum } from "../../shared/types";

const CreateQuotationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state }: any = useLocation();

  const cleanAndNavigate = async () => {
    if (!state || !state.from) {
      await dispatch(cleanClientStateAction());
    }

    navigate(state && state.from ? state.from : Paths.ROOT, {
      state: {
        formType: state && state.formType,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
      },
    });
  };

  return (
    <AuthenticatedRoute
      path={Paths.NEWQUOTATION}
      withHeader
      headerNavigationName={"Nueva Cotización"}
      goBack={() => cleanAndNavigate()}
    >
      {!isLoading && (
        <>
          <QuoteAndOrderMainForm
            showSearchButton
            formType={FormTypeEnum.Quote}
          />
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default CreateQuotationPage;
