import { takeLatest, put, call } from "redux-saga/effects";
import { apiGet } from "../../shared/ApiService";

import {
  getConfigurationParamsFailAction,
  getConfigurationParamsSuccessAction,
} from "./actions";
import { GET_CONFIGURATION_PARAMS } from "./actionTypes";

function* onGetConfigurationParams(payload: any) {
  try {
    const response: object = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.url,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getConfigurationParamsSuccessAction(response));
  } catch (error: any) {
    yield put(getConfigurationParamsFailAction(error));
  }
}

function* ConfigurationSaga() {
  yield takeLatest(GET_CONFIGURATION_PARAMS, onGetConfigurationParams);
}

export default ConfigurationSaga;
