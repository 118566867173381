import { Col, Form, Row, Input, FormInstance } from "antd";
import React from "react";
import { SimpleSearchFormProps } from "../../../shared/types";

const SimpleSearchForm = (props: SimpleSearchFormProps) => {
  const { Search } = Input;
  const formRef = React.createRef<FormInstance>();

  return (
    <Row justify="center" align="middle" style={{ marginTop: 20 }}>
      <Col xs={20} xl={10}>
        <Form
          name="basic"
          initialValues={undefined}
          layout="vertical"
          ref={formRef}
        >
          <Form.Item
            validateTrigger={["onChange", "onBlur"]}
            label=""
            name={props.inputName}
            rules={[]}
          >
            <Search
              name={props.inputName}
              size="large"
              onSearch={(value) => props.onSearch && props.onSearch(value)}
              enterButton
              placeholder={props.placeholder}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default SimpleSearchForm;
