import * as types from "./actionTypes";

export const getUserInfoAction = (userUrl: string) => {
  return {
    type: types.GET_USER_INFO,
    userUrl,
  };
};

export const getUserInfoActionFail = (error: any) => {
  return {
    type: types.GET_USER_INFO_FAIL,
    payload: error,
  };
};

export const getUserInfoActionSuccess = (user: any) => {
  return {
    type: types.GET_USER_INFO_SUCCESS,
    payload: user,
  };
};

export const getResumeAction = (userUrl: string) => {
  return {
    type: types.GET_RESUME,
    userUrl,
  };
};

export const getResumeActionFail = (error: any) => {
  return {
    type: types.GET_RESUME_FAIL,
    payload: error,
  };
};

export const getResumeActionSuccess = (resume: any) => {
  return {
    type: types.GET_RESUME_SUCCESS,
    payload: resume,
  };
};
