import { IProductAttr } from "../../core/models/attributes";
import { IQuoteDetailQualitative } from "../../core/models/products";
import { findIndex } from "../helper";
import { FormTypeEnum } from "../types";

/**
 *  CREATE/EDIT 
 * @param productData 
 * @returns genera los atributos cualitativos para cada producto para la variable "productQuote[].detalle.qualitative"
 * esta vaiable es la que sera usada para mapear los atributos en pantalla.
*/
 const generateProductQualitativeAttributes = ( atributosDetalle: IProductAttr[], value?:IQuoteDetailQualitative[] ) : IQuoteDetailQualitative[] => {

    let elements: any = [];
    
    atributosDetalle
    .filter((attrFilter: IProductAttr) => {
      return attrFilter.atributo_item != null;
    })
    .forEach((atributoDetalle: IProductAttr) => {
      const description = atributoDetalle.atributo.descripcion_comercial;
  
      const elementToPush = {
        _id: atributoDetalle._id,
        descripcion_comercial: atributoDetalle.atributo_item.nombre,
      };
  
      const firstIndex = findIndex(elements, "descripcion_comercial", description);
  
      if (firstIndex == -1) {
        elements.push({
          producto_id: atributoDetalle.producto_id,
          descripcion_comercial: description,
          items: [elementToPush],
          index: atributoDetalle.atributo._id,
          initialValue: null,
          selected: null
        });
      } else {
        elements[firstIndex].items.push(elementToPush);
      }    
    });
  
    if( value && value.length )
    {
      elements = elements.map(( product:any ) => 
      {
        const defaultValues = getDefaultValues(product, value);
        const updated = {
          ...product,
          initialValue: defaultValues.seleccionado?._id || null,
          selected: ( defaultValues.seleccionado?._id && defaultValues ) || ( defaultValues.atrib_id && defaultValues )
        }
        return updated;
      });
    }
  
    return [ ...elements ];
  };
  
  /**
   * 
   * @param product 
   * @param values 
   * @returns en caso de que el valor previamente seleccionado este presente en la nueva lista de atributos cualitativos, setea como default el mismo
   * de lo contrario null.
   */
  const getDefaultValues = (product: IQuoteDetailQualitative, values:IQuoteDetailQualitative[]) => 
  {
  
    const { items } = product;
    const initialValue = { atrib_id: null, seleccionado: { _id:null } };
  
    const matchedValue = values.map((value:any) => {
      if(value.seleccionado)
      {
        return value;
      } else {
        return { 
            atrib_id: value.selected?.atrib_id,
            seleccionado: value.selected?.seleccionado,
            descripcion_comercial: value.descripcion_comercial
        }
      }
    }).find((value:any) => {
        return value.descripcion_comercial == product.descripcion_comercial
    });
   
    const isValuePresent = items.some(( item ) => {
      const { _id: optionItemId } = item;
      return optionItemId == matchedValue.seleccionado?._id
    });
    
    if( isValuePresent )
    {
        return {
            atrib_id: matchedValue.atrib_id,
            seleccionado:matchedValue.seleccionado
        };
    }
    
    return {
        ...initialValue,
        atrib_id:matchedValue.atrib_id
    };
  };


  /**
   * //////////////////////////////////
   */

  /**
 * VIEW/EDIT
 * @param quoteElements 
 * @param attributeData 
 * @returns 
 */
const getProductQualitativeAttributes = ( quoteElements: any, attributeData: any, formType: string ) => 
{
  const { items } = quoteElements;

  const updated = {
    ...quoteElements,
    items:items.map(( product: any) => 
    {
      const { productos_hijos } = product;
      const arrayDetail:any       = [...product.atributos];
  
      productos_hijos.forEach(( childProducts: any) => 
      {
        const { atributos } = childProducts;
  
        atributos.forEach(( attr: any ) => {
          const index = findIndex(
            attributeData.attrs,
            "_id",
            attr.atributo.atributo_id
          );
          if (index !== -1) 
          {
            const newAttr = { ...attr };
            const a = attributeData.attrs[index];
  
            newAttr.atributo.atributo = {
              descripcion_comercial: a.descripcion_comercial,
              nombre: a.nombre,
              tipodeatributo_id: a.tipodeatributo_id,
              _id: a._id
            };
  
            arrayDetail.push(newAttr);
          }
        });
      });
  
      const newArrayFilter = arrayDetail.filter((attrFilter: any) => {
        return attrFilter.atributo.atributo_item != null;
      });
  
      return {
        ...product,
        qualitative: ( formType == FormTypeEnum.Order ) ? newArrayFilter : []
      };

    })
  } 

  return { ...updated };
};

  export {
    generateProductQualitativeAttributes,
    getProductQualitativeAttributes
  }