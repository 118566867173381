import { Col, Form, Row, Input, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { SimpleSearchFormProps } from "../../../shared/types";
import * as _ from "lodash";

const FormItemComponent = (props: any) => {
  const [rules, setRules] = useState<any[]>([]);

  useEffect(() => {
    if (props.rules && props.rules.length > 0) {
      const newRules = _.map(props.rules, (r: any) => {
        switch (r.name) {
          case "required":
            return {
              required: true,
              message: props.message
                ? props.message
                : `El campo ${props.inputName} es obligatorio.`,
            };
          case "minOrMax":
            return {
              validator: (_: any, value: number | null) => {
                const min = r.min;
                const max = r.max;
                let error = undefined;
                if (value != null) {
                  if (min && value < min) {
                    error = `El valor minimo a ingresar debe ser mayor igual a ${min}`;
                  } else if (max && value > max) {
                    error = `El valor maximo a ingresar debe ser menor igual a ${max}`;
                  }
                }
                if (error) {
                  return Promise.reject(error);
                }
                return Promise.resolve();
              },
            };
        }
      });

      setRules(newRules);
    }
  }, [props.rules]);

  return (
    <Form.Item
      key={props.inputName}
      validateTrigger={["onChange"]}
      label={props.inputLabel ? props.inputLabel : ""}
      name={props.inputName}
      rules={rules}
      style={{ marginBottom: "8px" }}
    >
      {props.children}
    </Form.Item>
  );
};

export default FormItemComponent;
