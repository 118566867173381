import * as types from "./actionTypes";

const initialState = {
  orderQuote: undefined,
  orderSaved: undefined,
  ordersSearch: undefined,
  orderToCancel: undefined,
  pricesList: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const OrdersReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.CLEAN_ORDER_CREATION_STATE:
    case types.SET_ORDER_TO_CANCEL:
    case types.ADD_ORDER_TO_QUOTE:
    case types.GET_ORDERS_SEARCH:
    case types.GET_PRICES_LIST:
    case types.SAVE_ORDER:
      state = { ...state, loading: true };
      break;

    case types.GET_ORDERS_SEARCH_SUCCESS:
      state = { ...state, ordersSearch: actionPayload, loading: false };
      break;

    case types.ADD_ORDER_TO_QUOTE_SUCCESS:
      state = { ...state, orderQuote: actionPayload, loading: false };
      break;

    case types.CLEAN_ORDER_CREATION_STATE_SUCCESS:
      state = { ...initialState };
      break;

    case types.GET_PRICES_LIST_SUCCESS:
      state = { ...state, pricesList: actionPayload, loading: false };
      break;

    case types.SAVE_ORDER_SUCCESS:
      state = { ...state, orderSaved: actionPayload, loading: false };
      break;

    case types.SET_ORDER_TO_CANCEL_SUCCESS:
      state = { ...state, orderToCancel: actionPayload, loading: false };
      break;

    case types.CLEAN_ORDER_CREATION_STATE_FAIL:
    case types.SET_ORDER_TO_CANCEL_FAIL:
    case types.ADD_ORDER_TO_QUOTE_FAIL:
    case types.GET_ORDERS_SEARCH_FAIL:
    case types.GET_PRICES_LIST_FAIL:
    case types.SAVE_ORDER_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }
  return state;
};

export default OrdersReducer;
