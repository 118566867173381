import React from "react";

import { Col, Divider, Image, Menu } from "antd";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import menuLogo from "../../../assets/logo/menuLogo.png";
import * as _ from "lodash";
import { Paths } from "../../../config/paths";

import drafts from "../../../assets/icon/dashboardMenu/drafts.png";
import quotes from "../../../assets/icon/dashboardMenu/quotes.png";
import newQuote from "../../../assets/icon/dashboardMenu/newQuote.png";
import newOrder from "../../../assets/icon/dashboardMenu/newOrder.png";
import orders from "../../../assets/icon/dashboardMenu/orders.png";
import dollar from "../../../assets/icon/dashboardMenu/dollar.png";
import settings from "../../../assets/icon/dashboardMenu/settings.png";
import usefulFiles from "../../../assets/icon/dashboardMenu/usefulFiles.png";
import Dater from "../../../assets/icon/dashboardMenu/dater.png";
import Logout from "../../../assets/icon/dashboardMenu/logout.png";

import { FormTypeEnum } from "../../../shared/types";
import { cleanClientStateAction } from "../../../store/clients/actions";
import {
  addProductToQuoteAction,
  cleanProductStateAction,
} from "../../../store/products/actions";
import { cleanQuoteToQuoteAction } from "../../../store/quotes/actions";
import { cleanOrderStateAction } from "../../../store/orders/actions";
import { hasPermission } from "../../../core/auth/AuthService";

const MainMenu = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { collapsed, setCollapsed } = props;

  const cleanData = async () => {
    await dispatch(cleanClientStateAction());
    await dispatch(cleanProductStateAction());
    await dispatch(cleanQuoteToQuoteAction());
    await dispatch(cleanOrderStateAction());
    await dispatch(addProductToQuoteAction([]));
  };

  const cleanAndNavigate = async (path: string) => {
    await cleanData();
    navigate(path);
  };
  const MenuConst: any = [
    {
      action: async () => {
        await cleanData();
        setCollapsed();
      },
      image: (
        <Image
          style={{
            objectFit: "contain",
            height: 55,
            marginTop: 20,
          }}
          src={menuLogo}
          preview={false}
        />
      ),
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.NEWQUOTATION);
      },
      icon: newQuote,
      name: "Nueva Cotización",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.QUOTATION);
      },
      icon: quotes,
      name: "Mis Cotizaciones",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.NEWORDER);
      },
      icon: newOrder,
      name: "Nuevo Pedido",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(`${Paths.ORDER}/${FormTypeEnum.Order}`);
      },
      icon: orders,
      name: "Mis Pedidos",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(`${Paths.ORDER}/${FormTypeEnum.Draft}`);
      },
      icon: drafts,
      name: "Mis Borradores",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.CONFIGURATION);
      },
      icon: settings,
      name: "Mi Configuración",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.BALANCE);
      },
      icon: dollar,
      name: "Mi Saldo",
      show: true
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.USEFULFILES);
      },
      icon: usefulFiles,
      name: "Descargas",
      show: hasPermission('MENU_ADMINISTRACION_DESCARGAS')
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.DATER);
      },
      icon: Dater,
      name: "Fechador",
      show: hasPermission('MENU_FECHADOR')
    },
    {
      action: async () => {
        cleanAndNavigate(Paths.LOGOUT);
      },
      icon: Logout,
      name: "Cerrar sesión",
      show: true
    },
  ].filter((row)=>{
    return row.show;
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "rgba(112, 111, 110, 0.6)",
        position: "absolute",
        overflowY: "auto",
        overflowX: "hidden",
        top: 0,
      }}
      onClick={() => setCollapsed()}
    >
      <div
        style={{
          height: "100%",
          backgroundColor: "#438EB9",
          boxShadow: "5px 0px 50px 5px rgba(78,78,78,0.88)",
          textAlign: "center",
          position: "absolute",
          paddingTop: 25,
          zIndex: 1,
          top: 0,
          width: 309,
          right: -1,
          paddingBottom: 25,
        }}
      >
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          style={{ backgroundColor: "#438EB9" }}
        >
          {_.map(MenuConst, (m, index: number) => {
            return (
              <>
                <Menu.Item
                  key={`Menu-Item-${index}`}
                  icon={
                    m.icon && (
                      <Image
                        style={{
                          objectFit: "contain",
                        }}
                        src={m.icon}
                        preview={false}
                      />
                    )
                  }
                  style={{
                    backgroundColor: "#438EB9",
                    marginBottom: m.image ? 50 : 0,
                  }}
                  className="main_menu-text"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => m.action()}
                  >
                    {m.image ? m.image : m.name}
                  </a>
                </Menu.Item>

                {!m.image && m.name !== "Cerrar sesión" && (
                  <Col span={22}>
                    <Divider
                      style={{
                        backgroundColor: "#FFFFFF",
                        marginLeft: 15,
                        marginTop: 10,
                        marginBottom: 10
                      }}
                    />
                  </Col>
                )}
              </>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default MainMenu;
