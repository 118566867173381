import { takeLatest, put, call } from "redux-saga/effects";

import {
  ADD_QUOTE_TO_QUOTE,
  CLEAN_QUOTE_TO_QUOTE,
  GET_QUOTE_SEARCH,
  SAVE_QUOTE,
} from "./actionTypes";

import { apiGet, apiPost, apiPut } from "../../shared/ApiService";
import { QuoteAndOrderDataProps } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import {
  addQuoteToQuoteFail,
  addQuoteToQuoteSuccess,
  cleanQuoteToQuoteFail,
  cleanQuoteToQuoteSuccess,
  getQuoteSearchFail,
  getQuoteSearchSuccess,
  saveQuoteFail,
  saveQuoteSuccess,
} from "./actions";

function* onSaveQuote(payload: any) {
  try {
    const response: QuoteAndOrderDataProps = yield call(() => {
      const id = payload.quote && payload.quote._id;
      if (id) {
        return Promise.resolve(
          apiPut({
            url: `${API_URL}/${Endpoints.SAVE_GET_QUOTE}/${id}`,
            body: payload.quote,
            unauthorizedCallback: () => {},
          })
        );
      } else {
        return Promise.resolve(
          apiPost({
            url: `${API_URL}/${Endpoints.SAVE_GET_QUOTE}`,
            body: payload.quote,
            unauthorizedCallback: () => {},
          })
        );
      }
    });
    yield put(saveQuoteSuccess(response));
  } catch (error: any) {
    yield put(saveQuoteFail(error.response));
  }
}

function* onGetQuoteForSearch(payload: any) {
  try {
    const response: object = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: payload.quotationUrl,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getQuoteSearchSuccess(response));
  } catch (error: any) {
    yield put(getQuoteSearchFail(error));
  }
}

function* onAddQuoteToQuote(payload: any) {
  try {
    const response: object = yield call(() =>
      Promise.resolve(
        apiGet({ url: payload.quote, unauthorizedCallback: () => {} })
      )
    );
    yield put(addQuoteToQuoteSuccess(response));
  } catch (error: any) {
    yield put(addQuoteToQuoteFail(error.response));
  }
}

function* onCleanQuoteToQuote() {
  try {
    yield call(() => {
      return Promise.resolve();
    });
    yield put(cleanQuoteToQuoteSuccess());
  } catch (error: any) {
    yield put(cleanQuoteToQuoteFail(error.response));
  }
}

function* QuoteSaga() {
  yield takeLatest(SAVE_QUOTE, onSaveQuote);
  yield takeLatest(ADD_QUOTE_TO_QUOTE, onAddQuoteToQuote);
  yield takeLatest(CLEAN_QUOTE_TO_QUOTE, onCleanQuoteToQuote);
  yield takeLatest(GET_QUOTE_SEARCH, onGetQuoteForSearch);
}

export default QuoteSaga;
