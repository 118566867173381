import React, { useEffect, useState } from "react";

import { Alert, Button, Col, Form, FormInstance, message, Row, Space, Tooltip } from "antd";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { Paths } from "../../config/paths";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AttributeTypes,
  InputNumberType,
  ReduxType,
} from "../../shared/types";
import {
  getProductsAttrAction,
  getProductsChildAction,
  getProductsComplementAction,
  cleanProductStateAction,
  addProductToQuoteAction,
  getProductByIdAction,
  setAttrValueNewProductAction,
  deleteChildProductAction,
} from "../../store/products/actions";
import { InputNumber, InputSelect } from "../../components/inputs";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import * as _ from "lodash";
import { Separator } from "../../components/separator";
import {
  findIndex,
  generateProductQuoteToRequestDetail,
  getBonif,
  getProductFinalCost,
  getProductSalePrice,
  getProfitability,
} from "../../shared/helper";
import { getAttrAction } from "../../store/attributes/actions";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { apiPost } from "../../shared/ApiService";
import { IProductAttr } from "../../core/models/attributes";
import { IProductChildData, IQuoteDetailQuantitative } from "../../core/models/products";
import NumberFormat from 'react-number-format';
import { CustomModal } from "../../components/modal";
import okModalIcon from "../../assets/icon/modalIcons/ok.png";
import { WarningOutlined } from "@ant-design/icons";
import { hasPermission } from "../../core/auth/AuthService";

const CreateProductPage = () => {
  /**
   * productData: contiene todos los datos asociados al producto principal seleccionado.
   * attributeData: contiene la totalidad de los atributos de la tabla 'atributos'.
   * clientData: contiene los datos del usuario/cliente seleccionado.
   */
  const { attributeData, productData, clientData } = useSelector((state: ReduxType) => 
  {
      return state;
  });

  const navigate      = useNavigate();
  const dispatch      = useDispatch();
  const { state }:any = useLocation();  
  const [isLoading, setIsLoading]       = useState(false);
  const [productPrice, setProductPrice] = useState<number>(0);
  const [quantity, setQuantity] = useState(0);
  const [tolerancesAlertMessages, setTolerancesAlertMessages ] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const formRef = React.createRef<FormInstance>();

  const [ selectedProducts, setSelectedProducts ] = useState<any>({
    mainProduct : {},
    attributes :[],
    childProducts :[]
  });

  useEffect(()=> {

  },[]);

  /**
   * Se observan los cambios en los productos hijos seleccionados y se extraen
   * el id y el grupo id, para agregarlo al estado (selectedProducts) que sirve para consultar
   * el precio del producto al backend.
  */
  useEffect(()=> {
    if( !_.isEmpty(productData.productAppendChild) )
    {
      const parsedSelected = Object.keys(productData.productAppendChild).map(( key: string ) => {
        return {
          id:productData.productAppendChild[key].value,
          groupid:productData.productAppendChild[key].groupid
        }      
      });

      parsedSelected.push(getMainProduct());

      setSelectedProducts({
        ...selectedProducts,
        childProducts:parsedSelected
      });
      checkToleranceAlertMessages();
    }
  },[productData.productAppendChild]); 

  useEffect(()=> {
    setSelectedProducts({
      ...selectedProducts,
      attributes:productData.attr?.map(( attr:IProductAttr )=>{
        return {
          id:attr._id,
          groupid: attr.grupo_id,
          base: isMinimumChargeable(attr.base, attr.base_min_cobrable),
          altura: isMinimumChargeable(attr.altura, attr.altura_min_cobrable),
          noCotiza: attr.no_cotiza
        }
      })
    });
  },[productData.attr]);


  /**
   * 
   * @param size 
   * @param minimum 
   * 
   * valida si la medida colocada por el usuario es inferior a la medida minima cobrable
   * del producto, en ese caso, se establece el minimo cobrable para el calculo del precio base
   * 
   * de no entrar en esa condicion, se devuelve la medida ingresada por el usuario, o 1 en caso
   * de ser un atributo indefinido.
   */
  const isMinimumChargeable = ( size:number, minimum:number ) => {

      if(size < minimum) {
        return minimum;
      }
  
      return size || 1;
  }

  useEffect(() => {
    if (!attributeData.attrs) {
      doAttrRequest();
    }
  }, [attributeData.attrs]);

  useEffect(() => {
    if (productData.product)
    {
      setSelectedProducts({
        ...selectedProducts,
        mainProduct:getMainProduct(),
        childProducts:[getMainProduct()]
      });

      if(!productData.product.editProduct)
      {
        doRequest(productData.product._id);
      }

    } else {
      navigate(Paths.NEWQUOTATION, {
        state: {
          formType: state && state.formType,
          editMode: state && state.editMode,
          canEdit: state && state.canEdit,
          ref: state && state.ref,
        },
      });
    }

  }, [productData.product]);


  /* 
  funcion que obtiene el id y el grupo del producto principal,
  se debe evaluar si es una edición de un producto, para determinar de
  de recuperar los datos ya sea diractamente de product o de product.producto (en caso de edicion) */

  const getMainProduct = () => {

    const { product } = productData;

    if( product.editProduct )
    {
      return {
        id:product.producto._id,
        groupid:product.producto.tipogrupo_id
      }
    }

    return {
      id:product._id,
      groupid:product.tipogrupo_id
    }
  }

  const doRequest = async (productId: number) => {
    await dispatch(getProductByIdAction(productId));
    await dispatch(getProductsAttrAction(productId));
    await dispatch(getProductsChildAction(productId, "",false, 0));
    await dispatch(getProductsComplementAction(productId));
  };

  useEffect(()=> {
    if( !_.isEmpty( selectedProducts.mainProduct ) && 
        !_.isEmpty(selectedProducts.childProducts) )
    {
      findProductPrice();
    }    
  },[selectedProducts]);

  const findProductPrice = async () => {
    try {
      const url = `${API_URL}/${Endpoints.GET_PRODUCT_PRICE_BASE }`;
      const response = await apiPost({
        url,
        body: { ...selectedProducts },
        unauthorizedCallback: () => {},
      });

      setProductPrice(response.precio);
    } catch (error: any) {
      message.error(error);
    }
  };

  const doAttrRequest = async () => {
    await dispatch(getAttrAction());
  };

  const cleanAndNavigate = async () => {
    await dispatch(cleanProductStateAction());

    navigate(state && state.from ? state.from : Paths.PRODUCT, {
      state: {
        formType: state.formType,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
      },
    });
  };

  const handleSubmit = async (values: any) => {
    const newProductQuotes = generateProductQuoteToRequestDetail(
      {...values},
      {...productData},
      productPrice
    );
    await dispatch(cleanProductStateAction());
    await dispatch(addProductToQuoteAction(newProductQuotes));
    navigate(Paths.PRODUCTDETAIL, {
      state: {
        formType: state && state.formType,
        editMode: state && state.editMode,
        canEdit: state && state.canEdit,
        ref: state && state.ref,
      },
    });
  };

  const getInitialValueForSelect = (descripcion_comercial: string) => {

    const { product } = productData;
    if ( product && product.detalle?.childProducts?.length ) {
      const index = findIndex(
        productData.product.detalle.childProducts,
        "name",
        descripcion_comercial
      );
      if (index != -1) {
        const productId = product.detalle.childProducts[index].value;
        return productId;
      }
    }
    return undefined;
  };

  const getProductChild = async (descripcion: string, groupid:number, productId: number) => {
    if (productId) {
      await dispatch(getProductsChildAction(productId, descripcion, true, groupid));
    }else{
      await dispatch(deleteChildProductAction(groupid));
    }
  };

  const getQuantativeInitialValue = ( atributo_id: number, axis: string ) => 
  {
    const { product: { detalle } } = productData;
    
    if(detalle)
    {
      const { quantitative } = detalle;
      
      const atribute = quantitative.find(( quantitative:IQuoteDetailQuantitative ) => atributo_id == quantitative.atributo_id);
      return atribute.value[axis] || null;
    }
    return null;
  };


/**
 * 
 * @param value 
 * @param formIndex 
 * @param fieldKey 
 * 
 * se modifica dinamicamente los valores de base/altura de los atributos de los
 * productos representados en pantalla para actualizarlos en el store.
 */
  const handleQuantitativeOnChange = (value:number, formIndex:number, fieldKey:keyof IProductAttr ) => {
    const modifiedAttr = productData.attr.map(( attr: IProductAttr, innerIndex:number) => {
      if(innerIndex === formIndex){
        attr[fieldKey] = value;
      }
      return attr;
    }) 

    dispatch(setAttrValueNewProductAction(modifiedAttr));
  }

  const filterByTolerance = (childProducts:any[]) => {

    const { attr:attributes } = productData;

    if(!_.isEmpty(childProducts))
    {
      return childProducts.filter(( childProduct:any ) => 
      {
        //inicio web
        const tolerancias = _.groupBy(childProduct.tolerancias, 'atributo_id');
				if(!_.isEmpty(tolerancias)) 
        {          

					return _.every( tolerancias, (tolerancia:any, keyAttribute_id:string ) => 
          {
						const at = _.find( attributes , (attr) => 
            {
              return attr._id == parseInt(keyAttribute_id);
						});

						return !at || _.some(tolerancia, function(r) 
            {
							const validWidth = !at.base || (at.base >= r.min_base && at.base <= r.max_base);
							const validHeight = !at.altura || (at.altura >= r.min_altura && at.altura <= r.max_altura);
							return validWidth && validHeight && r.tipo_tolerancia_id == 1;
						});
					});
				}
        
				return true;
        //fin web
      });
    }

    return [];
  }

  const checkToleranceAlertMessages = async () => {
    /* 
      productData.attributes: contiene el array con todos los atributos 
      productData.productChild: contiene todos los productos hijos del producto principal
      innerTolerancesAlertMessages:  array para ir agregando todos los mensajes de alerta que al final seran setteados en el estado de alertas.
    */
    const { attr:attributes, productChild:childProducts } = productData;
    const innerTolerancesAlertMessages:any = [];

    childProducts.forEach(( childProduct: IProductChildData ) =>
    {
      /* 
        se verifica que el producto hijo que se esta iterando tenga un valor seleccionado.
        en este caso, productAppendChild almacena los values del producto seleccionado
        asi que se verifica con la descripcion comercial ejemplo: telas, mecanismos
      */
      if( productData.productAppendChild[childProduct.descripcion_comercial] )
      {

        const selectedId = productData.productAppendChild[childProduct.descripcion_comercial].value;

        const completeProductSelected = childProduct.items.find((item)=> {
          return item._id === selectedId;
        })
        
        const tolerancesGroupedById:any = _.groupBy( completeProductSelected.tolerancias, 'atributo_id');
        if(!_.isEmpty(tolerancesGroupedById))
        {
            _.every( tolerancesGroupedById, (tolerances:any, productAtributeId:string) => {

                const currentProductAttributes = _.find( attributes , (attr) => 
                {
                  return attr._id == parseInt(productAtributeId);
                });

                if( currentProductAttributes )
                {
                    const filtered =_.filter(tolerances, (tolerance) => 
                    {
                      const base =  currentProductAttributes.valor || currentProductAttributes.base;
                      const cumple_base = !base || ( base >= tolerance.min_base && base <= tolerance.max_base );
                      const cumple_altura = !currentProductAttributes.altura || (currentProductAttributes.altura >= tolerance.min_altura && currentProductAttributes.altura <= tolerance.max_altura);

                      return cumple_base && cumple_altura && tolerance.tipo_tolerancia_id == 2;
                    });

                    filtered.forEach( function (tol) {
                      innerTolerancesAlertMessages.push({id:tol._id, mensaje:tol.mensaje});
                    });
                }
            });
        }
        setTolerancesAlertMessages(innerTolerancesAlertMessages);
      }

    });
  };

  const getMessage = () => {
    let message = [];

    message = tolerancesAlertMessages.map((alertMessage:any) => {
      return alertMessage.mensaje;
    });

    message.push(<b><br/>¿Desea continuar?</b>);
    return message;
  };

  const handleBeforeSubmit = async ( values:any ) => {
    if(tolerancesAlertMessages.length)
    {
      setIsModalVisible(true);
    } else {
      handleSubmit(values);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <AuthenticatedRoute
      path={Paths.NEWPRODUCT}
      withHeader
      goBack={() => cleanAndNavigate()}
      headerNavigationName={"Nuevo Producto"}
    >
      {!isLoading && (
        <>
          <CustomModal
            closable={true}
            isModalVisible={isModalVisible}
            handleOk={() => handleSubmit(formRef.current?.getFieldsValue())}
            handleCancel={() => handleModalCancel()}
            contentMessage={getMessage()}
            showOk={true}
            showCancel={true}
          />
          <Form
            name="basic"
            initialValues={undefined}
            onFinish={handleBeforeSubmit}
            layout="vertical"
            ref={formRef}
          >
            <Separator marginTop={20}>
              <FormItemComponent
                inputName={"quantity"}
                rules={[
                  { name: "required" },
                  {
                    name: "minOrMax",
                    min: 1,
                    max: Number.MAX_SAFE_INTEGER,
                  },
                ]}
              >
                <InputNumber
                  enabled
                  required
                  showPlaceholder
                  initialValue={
                    productData.product &&
                    productData.product.cantidad &&
                    productData.product.cantidad
                  }
                  onChange={(value) => setQuantity(value)}
                  type={InputNumberType.Number0}
                  name={"quantity"}
                  placeholder={"Cantidad"}
                />
              </FormItemComponent>

              {productData.attr &&
                productData.attr.length > 0 &&
                productData.attr.map((pa: IProductAttr, index: number) => {
                  const atributeType = pa.tipodeatributo_id;
                  return (
                    <Row key={`attr-inputs-${index}`}>
                      {atributeType == AttributeTypes.cuantitativoCompuesto && (
                        <>
                          <Tooltip
                            title={`Min ${pa.base_min} - Max ${pa.base_max}`}
                          >
                            <Col span={11}>
                              <FormItemComponent
                                inputName={"base"}
                                rules={[
                                  { name: "required" },
                                  {
                                    name: "minOrMax",
                                    min: pa.base_min,
                                    max: pa.base_max,
                                  },
                                ]}
                              >
                                <InputNumber
                                  enabled
                                  required
                                  showPlaceholder
                                  initialValue={getQuantativeInitialValue(pa.atributo_id, "base")}
                                  type={InputNumberType.Decimal3}
                                  name={"base"}
                                  placeholder={"Ancho"}
                                  onChange={ (val)=>{ handleQuantitativeOnChange(val, index, "base"); checkToleranceAlertMessages(); } }
                                />
                              </FormItemComponent>
                            </Col>
                          </Tooltip>

                          <Col
                            span={2}
                            style={{ textAlign: "center", marginTop: 12 }}
                          >
                            <span
                              className={
                                "custom-span-text custom-span-text-font"
                              }
                            >
                              X
                            </span>
                          </Col>
                          <Tooltip
                            title={`Min ${pa.altura_min} - Max ${pa.altura_max}`}
                          >
                            <Col span={11}>
                              <FormItemComponent
                                inputName={"altura"}
                                rules={[
                                  { name: "required" },
                                  {
                                    name: "minOrMax",
                                    min: pa.altura_min,
                                    max: pa.altura_max,
                                  },
                                ]}
                              >
                                <InputNumber
                                  enabled
                                  required
                                  showPlaceholder
                                  initialValue={getQuantativeInitialValue(pa.atributo_id, "altura")}
                                  type={InputNumberType.Decimal3}
                                  name={"altura"}
                                  placeholder={"Alto"}
                                  onChange={ (val) => { handleQuantitativeOnChange(val, index, "altura");checkToleranceAlertMessages(); } }
                                />
                              </FormItemComponent>
                            </Col>
                          </Tooltip>
                        </>
                      )}

                      {atributeType == AttributeTypes.cuantitativoSimple && (
                        
                          <Col span={24}>
                            <Tooltip
                              title={`Min ${pa.base_min} - Max ${pa.base_max}`}>
                              <FormItemComponent
                                inputName={`base_${pa._id}`}
                                rules={[
                                  { name: "required" },
                                  {
                                    name: "minOrMax",
                                    min: pa.base_min,
                                    max: pa.base_max,
                                  },
                                ]}
                              >
                                <InputNumber
                                  enabled
                                  required
                                  showPlaceholder
                                  initialValue={getQuantativeInitialValue(pa.atributo_id, "base")}
                                  type={InputNumberType.Decimal3}
                                  name={`base_${pa._id}`}
                                  placeholder={pa.nombre}
                                  onChange={ (val) => { handleQuantitativeOnChange(val, index, "base");checkToleranceAlertMessages(); } }
                                />
                              </FormItemComponent>
                            </Tooltip>
                          
                          </Col>  
                        
                      )}
                    </Row>
                  );
                })
            }

              {productData.productChild &&
                productData.productChild.length > 0 &&
                productData.productChild.map(
                  (pc: IProductChildData, index: number) => (
                    <FormItemComponent
                      inputName={`${pc.descripcion_comercial}`}
                      rules={[{ name: "required" }]}
                      key={`select-childproducts-${index}`}
                    >
                      <InputSelect
                        enabled
                        required
                        showPlaceholder
                        initialValue={ productData.product?.editProduct && getInitialValueForSelect( pc.descripcion_comercial)}
                        onChange={(value) => {
                          getProductChild(pc.descripcion_comercial, pc.grupoid, value); }
                        }
                        name={`${pc.descripcion_comercial}`}
                        placeholder={`${pc.nombre}`}
                        optionList={filterByTolerance(pc.items)}
                      />
                    </FormItemComponent>
                  )
                )}

              {productData.productAppendChild &&
                Object.keys(productData.productAppendChild).length > 0 &&
                Object.keys(productData.productAppendChild).map(
                  (key: any) =>
                    key &&
                    productData.productAppendChild[key].items.length > 0 &&
                    productData.productAppendChild[key].items.map(
                      (pc: IProductChildData, index: number) => (
                        <FormItemComponent
                          inputName={`${pc.descripcion_comercial}`}
                          rules={[{ name: "required" }]}
                          key={`select-productappendchild-${index}`}
                        >
                          <InputSelect
                            enabled
                            required
                            showPlaceholder
                            initialValue={ productData.product?.editProduct && getInitialValueForSelect(
                              pc.descripcion_comercial
                            )}
                            onChange={(value) => {
                              getProductChild(pc.descripcion_comercial,pc.grupoid, value);
                              checkToleranceAlertMessages();
                            }
                            }
                            name={`${pc.descripcion_comercial}`}
                            placeholder={`${pc.nombre}`}
                            optionList={pc.items}
                          />
                        </FormItemComponent>
                      )
                    )
                )}
            </Separator>
            <Separator marginTop={20}>
              <Space direction="vertical" style={{ width: '100%' }}>
                { tolerancesAlertMessages.map((alertMessage:any, index:number) => 
                    <Alert key={`alert-message-${index}`} icon={<WarningOutlined />} message={alertMessage.mensaje} type="warning" showIcon />
                )}
              </Space>
            </Separator>                  
            {
              hasPermission('READ_FINAL_COST') ? 
              <Separator marginTop={20}>
              <Space>
                <span className={"custom-span-text custom-span-text-font"}>
                  Costo Final
                </span>
                <span className={"custom-span-text"}>
                
                <NumberFormat 
                  value={productPrice} 
                  displayType={'text'} 
                  thousandSeparator={true}
                  decimalScale={2} 
                  fixedDecimalScale={true} 
                  prefix={'$'} />{` x ${quantity && quantity > 0 ? quantity : 0 } - (${getBonif(clientData)}%) = `}
                  
                  <span className={"custom-span-text-font"}>
                    <NumberFormat 
                      value={ getProductFinalCost(
                                productPrice,
                                clientData,
                                quantity,
                                true )
                            } 
                      displayType={'text'} 
                      thousandSeparator={true}
                      decimalScale={2} 
                      fixedDecimalScale={true} 
                      prefix={'$'} />
                    
                    
                  </span>
                </span>
              </Space>
            </Separator> : null
            }

            <Separator marginTop={20}>
              <Space>
                <span className={"custom-span-text custom-span-text-font"}>
                  Precio de venta
                </span>
                <span className={"custom-span-text"}>
                <NumberFormat 
                  value={getProductSalePrice ( productPrice, 1, getProfitability(clientData), getBonif(clientData))} 
                  displayType={'text'} 
                  thousandSeparator={true}
                  decimalScale={2} 
                  fixedDecimalScale={true} 
                  prefix={'$'} />
                  {` x                     
                    ${quantity && quantity > 0 ? quantity : 0} =`}{" "}
                  <span className={"custom-span-text-font"}>
                  <NumberFormat 
                    value={getProductSalePrice( productPrice, quantity, getProfitability(clientData), getBonif(clientData))} 
                    displayType={'text'} 
                    thousandSeparator={true}
                    decimalScale={2} 
                    fixedDecimalScale={true} 
                    prefix={'$'} />                  
                    
                  </span>
                </span>
              </Space>
            </Separator>

            <Separator marginTop={20}>
              <Button
                type="primary"
                htmlType="submit"
                className={"custom-button custom-button-blue"}
              >
                Agregar
              </Button>
            </Separator>

            <Separator marginTop={20}>
              <Button
                type="primary"
                onClick={() => cleanAndNavigate()}
                className={"custom-button"}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#828282",
                }}
              >
                Eliminar
              </Button>
            </Separator>
          </Form>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default CreateProductPage;
