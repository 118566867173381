import { takeLatest, put, call } from "redux-saga/effects";

import { GET_RUBRO } from "./actionTypes";

import { getRubroFail, getRubroSuccess } from "./actions";

import { apiGet } from "../../shared/ApiService";
import { ClientsProps } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";

function* onGetRubro(payload: any) {
  try {
    const response: ClientsProps = yield call(() => {
      return Promise.resolve(
        apiGet({
          url: `${API_URL}/${Endpoints.RUBRO}/${payload.clientId}`,
          unauthorizedCallback: () => {},
        })
      );
    });
    yield put(getRubroSuccess(response));
  } catch (error: any) {
    yield put(getRubroFail(error.response));
  }
}

function* RubroSaga() {
  yield takeLatest(GET_RUBRO, onGetRubro);
}

export default RubroSaga;
