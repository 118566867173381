export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";

export const LOGIN_PROFILE_USER = "LOGIN_PROFILE_USER";
export const LOGIN_PROFILE_FAIL = "LOGIN_PROFILE_FAIL";
export const LOGIN_PROFILE_SUCCESS = "LOGIN_PROFILE_SUCCESS";

export const RESET_USER = "RESET_USER";
export const RESET_USER_FAIL = "RESET_USER_FAIL";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
