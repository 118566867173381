export const SET_CLIENT = "SET_CLIENT";
export const SET_CLIENT_FAIL = "SET_CLIENT_FAIL";
export const SET_CLIENT_SUCCESS = "SET_CLIENT_SUCCESS";

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";

export const GET_CLIENT_ID = "GET_CLIENT_ID";
export const GET_CLIENT_ID_FAIL = "GET_CLIENT_ID_FAIL";
export const GET_CLIENT_ID_SUCCESS = "GET_CLIENT_ID_SUCCESS";

export const CLEAN_CLIENT_STATE = "CLEAN_CLIENT_STATE";
export const CLEAN_CLIENT_STATE_FAIL = "CLEAN_CLIENT_STATE_FAIL";
export const CLEAN_CLIENT_STATE_SUCCESS = "CLEAN_CLIENT_STATE_SUCCESS";
