import React, { CSSProperties } from "react";
import { IProductData } from "../core/models/products";

export interface DynamicIndexSignature 
{
   [key: string]: string | number;
}

export type DashboardComponentProps = {};

export interface HeaderProps {
  width: number;
  withLogo?: boolean;
  withDashboardLogo?: boolean;
  dashboard?: boolean;
  login?: boolean;
  collapsed: boolean;
  headerNavigationName?: string;
  toggleCollapsed: () => void;
  goBack?: () => void;
}

export type AuthenticatedRouteProps = {
  path: string;
  children: any;
  login?: boolean;
  withHeader?: boolean;
  withLogo?: boolean;
  dashboard?: boolean;
  withDashboardLogo?: boolean;
  headerNavigationName?: string;
  goBack?: () => void;
};

export type User = {
  name: string;
};

export type ReduxType = {
  attributeData: any;
  userData: any;
  clientData: any;
  rubroData: any;
  productData: IProductData;
  quoteData: any;
  ordersData: any;
  configurationData: any;
  balanceData: any;
  usefulFileData: IUsefulFilesData;
  daterData: any;
};

export enum InputNumberType {
  Number0 = "Number0",
  Number2 = "Number2",
  Number4 = "Number4",
  Decimal0 = "Decimal0",
  Decimal2 = "Decimal2",
  Decimal3 = "Decimal3",
  Decimal4 = "Decimal4",
  Percentage0 = "Percentage0",
  Percentage2 = "Percentage2",
  Percentage4 = "Percentage4",
}

export interface IUsefulFilesData {
  usefulFiles: {
    data: {
      archivo:string;
      cover:string;
      created_at:string;
      id_folder:number;
      nombre:string;
      orden:number;
      updated_at:string;
      _id:string;
    }[],
    loading: boolean;
  };
  folders: {
    _id:string;
    name:string;
    created_at:string;
    updated_at:string;
    deleted_at:string;
  }[];
  folderId:number;
  loading:boolean;
}

export interface LoginFormProps {
  username: string;
  password: string;
}

export interface InputValidation {
  regex?: { regex: string | undefined; message: string }[] | [];
  maxValue?: number | null;
  minValue?: number | null;
}

export type FilterProps = {
  initialValue?: any;
  name: string;
  classname?: string;
  onChange?: (e: any) => void;
  regex?: { regex: string | undefined; message: string };
  placeholder?: string;
  onBlur?: boolean;
  showPlaceholder?: boolean;
  enabled: boolean;
  required: boolean;
  prefix?: any;
  min?:number;
  checkboxLabel?: string;
  style?: CSSProperties | undefined;
  validation?: InputValidation;
  optionList?: Array<OptionListProps>;
};

export type OptionListProps = {
  _id: string | number;
  descripcion_comercial?: string;
  descripcion?: string;
};

export interface InputGeneralProps extends FilterProps {
  type: InputNumberType | "text" | "textarea";
  alignRight?: boolean;
}

export interface InputNumberTypePrecisionProps {
  type: string;
  precision?: number;
  step?: number;
}

export type InputOptionDisplayProps = {
  value: string | number;
};

export interface InputFloatLabelProps extends InputGeneralProps {
  floatLabel?: string;
}

export type KeyValuePair = {
  id: string;
  value: string;
};

export enum ConfirmDialogTypeEnum {
  Info = "Info",
  Exito = "Exito",
  Advertencia = "Advertencia",
  Eliminar = "Eliminar",
  Error = "Error",
}

export interface ConfirmDialogProps {
  message: string;
  type: ConfirmDialogTypeEnum;
  title?: string;
  okText?: string;
  onOk?: () => void;
  onCancel?: () => void;
}

export interface CardProps {
  withIcon?: boolean;
  marginIcon?: number;
  marginTop?: boolean;
  path: string;
  name: string;
  icon: any;
}
//---Api service props

export interface BaseApiParams {
  url: string;
  unauthorizedCallback?: any;
  showSuccesMessage?: boolean;
}

export interface GetParams extends BaseApiParams {}

export interface DeleteParams extends BaseApiParams {}

export interface PostParams extends BaseApiParams {
  body: object;
}

export interface PutParams extends BaseApiParams {
  body: any;
}

export interface PostDownloadParams extends PostParams {
  fileName: string;
  extension: string;
}

//------

export interface PaginateProps {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string | null;
  prev_page_url: string | null;
  from: number;
  to: number;
}

export interface ClientsProps extends PaginateProps {
  data: ClientDataProps[];
}
export interface ProductsProps extends PaginateProps {
  data: ProductDataProps[];
}
export interface OrdersProps extends PaginateProps {
  data: OrdersDataProps[];
}

export interface ProductDataProps extends PaginateProps {
  _id: string | number;
  photo: string;
  orden: string;
  cotiza: string;
  codigo: string;
  categoria_id: string;
  descripcion: string;
  descripcionadicional: string;
  es_complemento: string;
  habilitado: boolean;
  link: string | null;
  tipogrupo_id: string;
  tipo_medida_id: string;
  tipo_empaquetado_id: string;
  producto_asociado_id: string;
  complementos: any;
  atributos: any;
  configuracion_produccion: any;
  grupo: any;
  productos: any;
  tipo_empaquetado: any;
  tipo_medida: any;
  item?: any;
}

export interface OrdersDataProps {
  _id: string | number;
  anulado: boolean;
  aprobado: boolean;
  cliente: any;
  cliente_id: string | number;
  created_at: string;
  cuit_facturacion: string;
  deleted_at: string | null;
  en_produccion: boolean;
  fecha: string;
  fecha_aprobacion: string;
  fecha_entrega: string;
  forma_entrega: string;
  is_borrador: boolean;
  is_externo: boolean;
  is_mobile: boolean;
  listaprecio_id: string | number;
  motivo: string;
  observaciones: string;
  plazo_entrega: string | null;
  plazo_entrega_id: string | null;
  referencia: string;
  rentabilidad: string | number;
  total: number;
  updated_at: string;
  usuario: any;
  usuario_id: string | number;
  bonificacion: number;
}

export interface ClientDataProps {
  _id: string | number;
  codigo: string;
  razonsocial: string;
  fechaalta: Date;
  tipoposicionfiscal: string | number;
  parametro: string | number;
  identificador: string | number;
  nombrecomercial: string;
  email: string;
  condicion_venta_id: string | number;
  bonificacion: number;
  saldo: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export interface SimpleSearchFormProps {
  inputName: string;
  placeholder: string;
  onSearch: (value: string) => void;
}

export interface DaterFormProps {
  isModal?: boolean;
  selectedElement?: any;
  onHandleCancel?: () => void;
  updateList: () => void;
  initialRubroValue?: string;
  initialDateValue?: Date;
}
export interface UserInfoI {
  _id: string;
  codigo: string;
  razonsocial: string;
  fechaalta: string;
  tipoposicionfiscal: any;
  parametro: any;
  identificador: string;
  nombrecomercial: string;
  created_at: Date | null;
  updated_at: Date | null;
  email: string;
  deleted_at: Date | null;
  condicion_venta_id: string;
  bonificacion: number;
  saldo: number;
  usuarios: any[];
}

export interface ProductPriceDataProps {}

export interface QuoteAndOrderDataProps {
  bonificacion: number;
  cliente_id: string;
  created_at: Date;
  fecha: Date;
  listaprecio_id: string;
  referencia: string;
  rentabilidad: number;
  total: number;
  updated_at: Date;
  usuario_id: number;
  _id: number;
}

export interface CustomModalProps {
  name?: string;
  closable?: boolean;
  showOk?: boolean;
  showOkText?: string;
  showCancel?: boolean;
  showCancelText?: string;
  isModalVisible: boolean;
  contentMessage: any | any[];
  image?: string;
  antdIcon?: any;
  handleOk?: any;
  handleCancel?: () => void;
}

export interface ModalFormProps {
  title?: string;
  isModalVisible: boolean;
  isSubmitting?: boolean;
  handleCancel?: () => void;
  children?: any;
  cancelText: string;
  okText: string;
}

export enum FormTypeEnum {
  Quote = "Quote",
  Order = "Order",
  Draft = "Draft",
}

export interface QuoteAndOrderMainFormProps {
  showSearchButton?: boolean;
  formType: FormTypeEnum;
}

export interface ModalImageProps {
  clossable: boolean;
  isVisible: boolean;
  imageUrl: string;
  onHide: () => void;
}


export enum AttributeTypes {
  cualitativo = 1,
  cuantitativoSimple = 2,
  cuantitativoCompuesto = 3,
}

export enum quoteState {
  pendingApprove  = "0",
  annulled        = "1",
  finalized       = "2",
  approved        = "3",
  expired         = "4"
}
