import React, { useState } from "react";
import { HeaderComponent } from "../../components/base";
import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { cleanClientStateAction } from "../../store/clients/actions";
import { QuoteAndOrderMainForm } from "../../components/forms/quoteAndOrderMainForm";
import { FormTypeEnum, ReduxType } from "../../shared/types";

const CreateOrderPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state }: any = useLocation();

  const cleanAndNavigate = async () => {
    await dispatch(cleanClientStateAction());
    navigate(Paths.ROOT);
  };

  return (
    <AuthenticatedRoute
      path={Paths.NEWQUOTATION}
      withHeader
      goBack={() => cleanAndNavigate()}
      headerNavigationName={"Nuevo Pedido"}
    >
      {!isLoading && (
        <>
          <QuoteAndOrderMainForm
            showSearchButton
            formType={FormTypeEnum.Order}
          />
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default CreateOrderPage;
