import * as types from "./actionTypes";

const initialState = {
  usefulFiles: {
    data:undefined,
    loading:false
  },
  folders: undefined,
  folderId: undefined,
  loading: false,
  error: {
    message: "",
  },
};

const UsefulFileReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_USEFUL_FILES:
      state = { 
                ...state,
                usefulFiles: {
                  ...state.usefulFiles,
                  loading:true
                }
              };
      break;

    case types.GET_USEFUL_FILES_SUCCESS:
      state = { 
                ...state, 
                usefulFiles: {
                  ...state.usefulFiles,
                  data:actionPayload.usefulFiles,
                  loading:false
                }, 
                folderId:actionPayload.folderId,
                loading: false };
      break;

    case types.GET_FOLDERS:
      state = { ...state, loading: true }
      break;

    case types.GET_FOLDERS_SUCCESS:
      state = { ...state, folders: actionPayload, loading: false };
      break;

    case types.CLEAN_USEFUL_FILES_STATE:
      state = { 
        ...state, 
        usefulFiles: {
          ...state.usefulFiles,
          data:undefined,
          loading:false
        },
        folders:undefined,
        folderId: undefined,
        loading: false };
    break;

    case types.GET_USEFUL_FILES_FAIL:
    case types.GET_FOLDERS_FAIL:
      state = {
        ...state,
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;
  }

  return state;
};

export default UsefulFileReducer;
