import React, { useEffect, useRef, useState } from "react";
import { HeaderComponent } from "../../components/base";

import { useLocation } from "react-router";
import { useNavigate, useParams } from "react-router-dom";

import { Paths } from "../../config/paths";
import AuthenticatedRoute from "../../core/routes/AuthenticatedRoute";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance, message, Button } from "antd";
import { ReduxType } from "../../shared/types";
import { API_URL } from "../../config/general-config";
import { Endpoints } from "../../config/endpoints";
import { InputText, InputTextArea } from "../../components/inputs";
import Form from "antd/lib/form/Form";
import { FormItemComponent } from "../../components/forms/formItemComponent";
import { apiPost } from "../../shared/ApiService";
import { CustomModal } from "../../components/modal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import okModalIcon from "../../assets/icon/modalIcons/ok.png";
import { Separator } from "../../components/separator";

const OrderCancelPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<any>(undefined);

  const [isVisibleCancelPopup, setisVisibleCancelPopup] =
    useState<any>(undefined);

  const navigate = useNavigate();

  const params = useParams();

  const formRef = React.createRef<FormInstance>();

  const { ordersData } = useSelector((state: ReduxType) => {
    return state;
  });

  useEffect(() => {
    if (!ordersData.orderToCancel) {
      navigate(`${Paths.ORDER}/${params.orderType}`);
    }
  }, [ordersData.orderToCancel]);

  const cleanAndNavigate = async () => {
    setIsModalVisible(false);
    navigate(`${Paths.ORDER}/${params.orderType}`);
  };

  const handleSubmit = async (values: any) => {
    try {
      const body = { ...ordersData.orderToCancel, ...values };
      const url = `${API_URL}/${Endpoints.CANCEL_ORDER}/${body._id}`;
      const request = await apiPost({
        url,
        body: body,
        unauthorizedCallback: () => {},
      });
      request && setIsModalVisible(true);
      setIsModalVisible(true);
    } catch (error: any) {
      message.error(error);
    }
  };

  return (
    <AuthenticatedRoute
      path={Paths.CANCELORDER}
      withHeader
      goBack={() => navigate(`${Paths.ORDER}/${params.orderType}`)}
      headerNavigationName={"Anular pedido"}
    >
      {!isLoading && (
        <>
          <CustomModal
            closable={false}
            isModalVisible={isModalVisible}
            handleOk={() => cleanAndNavigate()}
            handleCancel={() => cleanAndNavigate()}
            image={okModalIcon}
            contentMessage={`El pedido #${ordersData.orderToCancel._id} fue anulado.`}
          />

          <Form
            name="basic"
            initialValues={undefined}
            onFinish={handleSubmit}
            layout="vertical"
            ref={formRef}
          >
            <Separator marginTop={20}>
              <FormItemComponent
                inputName={"motivo"}
                rules={[{ name: "required" }]}
              >
                <InputTextArea
                  enabled
                  required
                  showPlaceholder
                  type={"text"}
                  name={"motivo"}
                  placeholder={"Ingrese el motivo de anulación"}
                  style={{ height: 45 }}
                />
              </FormItemComponent>
            </Separator>

            <Separator marginTop={20}>
              <Button
                type="primary"
                htmlType="submit"
                className={"custom-button"}
                style={{
                  backgroundColor: "#438EB9",
                  color: "#FFFFFF",
                }}
              >
                Anular
              </Button>
            </Separator>

            <Separator marginTop={20}>
              <Button
                type="primary"
                onClick={() => cleanAndNavigate()}
                className={"custom-button"}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#828282",
                }}
              >
                Cancelar
              </Button>
            </Separator>
          </Form>
        </>
      )}
    </AuthenticatedRoute>
  );
};

export default OrderCancelPage;
