import * as types from "./actionTypes";

export const getConfigurationParamsAction = (url: string) => {
  return {
    type: types.GET_CONFIGURATION_PARAMS,
    url,
  };
};

export const getConfigurationParamsFailAction = (url: string) => {
  return {
    type: types.GET_CONFIGURATION_PARAMS_FAIL,
    url,
  };
};

export const getConfigurationParamsSuccessAction = (payload: any) => {
  return {
    type: types.GET_CONFIGURATION_PARAMS_SUCCESS,
    payload,
  };
};
