export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCT_FAIL = "SET_PRODUCT_FAIL";
export const SET_PRODUCT_SUCCESS = "SET_PRODUCT_SUCCESS";

export const SET_COMPLEMENTS = "SET_COMPLEMENTS";
export const SET_COMPLEMENTS_FAIL = "SET_COMPLEMENTS_FAIL";
export const SET_COMPLEMENTS_SUCCESS = "SET_COMPLEMENTS_SUCCESS";

export const GET_PRODUCTS_SELECT = "GET_PRODUCTS_SELECT";
export const GET_PRODUCTS_SELECT_FAIL = "GET_PRODUCTS_SELECT_FAIL";
export const GET_PRODUCTS_SELECT_SUCCESS = "GET_PRODUCTS_SELECT_SUCCESS";

export const GET_PRODUCTS_SEARCH = "GET_PRODUCTS_SEARCH";
export const GET_PRODUCTS_SEARCH_FAIL = "GET_PRODUCTS_SEARCH_FAIL";
export const GET_PRODUCTS_SEARCH_SUCCESS = "GET_PRODUCTS_SEARCH_SUCCESS";

export const GET_PRODUCTS_ATTR = "GET_PRODUCTS_ATTR";
export const GET_PRODUCTS_ATTR_FAIL = "GET_PRODUCTS_ATTR_FAIL";
export const GET_PRODUCTS_ATTR_SUCCESS = "GET_PRODUCTS_ATTR_SUCCESS";

export const GET_PRODUCTS_CHILD = "GET_PRODUCTS_CHILD";
export const GET_PRODUCTS_CHILD_FAIL = "GET_PRODUCTS_CHILD_FAIL";
export const GET_PRODUCTS_CHILD_SUCCESS = "GET_PRODUCTS_CHILD_SUCCESS";

export const GET_PRODUCTS_COMPLEMENTS = "GET_PRODUCTS_COMPLEMENTS";
export const GET_PRODUCTS_COMPLEMENTS_FAIL = "GET_PRODUCTS_COMPLEMENTS_FAIL";
export const GET_PRODUCTS_COMPLEMENTS_SUCCESS =
  "GET_PRODUCTS_COMPLEMENTS_SUCCESS";

export const CLEAN_PRODUCT_CREATION_STATE = "CLEAN_PRODUCT_CREATION_STATE";
export const CLEAN_PRODUCT_CREATION_STATE_FAIL =
  "CLEAN_PRODUCT_CREATION_STATE_FAIL";
export const GET_PRODUCTS_CHILD_APPEND_FAIL = "GET_PRODUCTS_CHILD_APPEND_FAIL";
export const CLEAN_PRODUCT_CREATION_STATE_SUCCESS =
  "CLEAN_PRODUCT_CREATION_STATE_SUCCESS";
export const GET_PRODUCTS_CHILD_APPEND_SUCCESS =
  "GET_PRODUCTS_CHILD_APPEND_SUCCESS";

export const ADD_PRODUCT_TO_QUOTE = "ADD_PRODUCT_TO_QUOTE";
export const ADD_PRODUCT_TO_QUOTE_FAIL = "ADD_PRODUCT_TO_QUOTE_FAIL";
export const ADD_PRODUCT_TO_QUOTE_SUCCESS = "ADD_PRODUCT_TO_QUOTE_SUCCESS";

export const ADD_PRODUCT_TO_QUOTE_TO_DELETE = "ADD_PRODUCT_TO_QUOTE_TO_DELETE";
export const ADD_PRODUCT_TO_QUOTE_TO_DELETE_FAIL =
  "ADD_PRODUCT_TO_QUOTE_TO_DELETE_FAIL";
export const ADD_PRODUCT_TO_QUOTE_TO_DELETE_SUCCESS =
  "ADD_PRODUCT_TO_QUOTE_TO_DELETE_SUCCESS";

export const UPDATE_QUALITATIVE_SELECTED_VALUE =
  "UPDATE_QUALITATIVE_SELECTED_VALUE";
export const UPDATE_QUALITATIVE_SELECTED_VALUE_FAIL =
  "UPDATE_QUALITATIVE_SELECTED_VALUE_FAIL";
export const UPDATE_QUALITATIVE_SELECTED_VALUE_SUCCESS =
  "UPDATE_QUALITATIVE_SELECTED_VALUE_SUCCESS";

export const GET_PRODUCT_PRICE_LIST = "GET_PRODUCT_PRICE_LIST";
export const GET_PRODUCT_PRICE_LIST_FAIL = "GET_PRODUCT_PRICE_LIST_FAIL";
export const GET_PRODUCT_PRICE_LIST_SUCCESS = "GET_PRODUCT_PRICE_LIST_SUCCESS";

export const PRODUCT_SEARCH_BY_ID = "PRODUCT_SEARCH_BY_ID";
export const PRODUCT_SEARCH_BY_ID_FAIL = "PRODUCT_SEARCH_BY_ID_FAIL";
export const PRODUCT_SEARCH_BY_ID_SUCCESS = "PRODUCT_SEARCH_BY_ID_SUCCESS";

export const GET_PRODUCT_COMPLETE_DATA = "GET_PRODUCT_COMPLETE_DATA";
export const GET_PRODUCT_COMPLETE_DATA_FAIL = "GET_PRODUCT_COMPLETE_DATA_FAIL";
export const GET_PRODUCT_COMPLETE_DATA_SUCCESS =
  "GET_PRODUCT_COMPLETE_DATA_SUCCESS";


export const SET_VAL_ATTR_PRODUCT_DATA = "SET_VAL_ATTR_PRODUCT_DATA";
export const DELETE_CHILD_PRODUCT_IN_APPEND =  "DELETE_CHILD_PRODUCT_IN_APPEND";

export const DELETE_PRODUCT_FROM_ORDER          = "DELETE_PRODUCT_FROM_ORDER";
export const DELETE_PRODUCT_FROM_ORDER_SUCCESS  = "DELETE_PRODUCT_FROM_ORDER_SUCCESS";
export const DELETE_PRODUCT_FROM_ORDER_FAILED   = "DELETE_PRODUCT_FROM_ORDER_FAILED";
