import * as types from "./actionTypes";

const initialState = {
  params: undefined,
  loading: false,
};

const ConfigurationReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  const actionType = action.type;
  const actionPayload = action.payload;
  switch (actionType) {
    case types.GET_CONFIGURATION_PARAMS:
    case types.GET_CONFIGURATION_PARAMS_FAIL:
      state = { ...state, loading: true };
      break;
    case types.GET_CONFIGURATION_PARAMS_SUCCESS:
      state = { ...state, params: actionPayload, loading: false };
      break;
  }

  return state;
};

export default ConfigurationReducer;
