import * as types from "./actionTypes";

export const getDaterAction = (daterUrl: string) => {
  return {
    type: types.GET_DATER,
    daterUrl,
  };
};

export const getDaterFail = (error: any) => {
  return {
    type: types.GET_DATER_FAIL,
    payload: error,
  };
};

export const getDaterSuccess = (usefulFiles: any) => {
  return {
    type: types.GET_DATER_SUCCESS,
    payload: usefulFiles,
  };
};
